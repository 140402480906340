@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
@import 'react-pdf/dist/esm/Page/TextLayer.css';
@import 'react-resizable/css/styles.css';
// @import 'react-image-lightbox/style.css';
// @import 'firebaseui/dist/firebaseui.css';

/* Common Variables */
$chat-color: #63A70A;
$post-color: #FF8300;
$content-color: #FFB819;
$user-color: #009BDE;
$refme-color: #F44336;
$unread-color: #27A9E1;
$vmoso-color: #0288D1;
$overlay-color: rgba(120, 144, 156, 0.20);
$gmMin: 3.75rem;

$headerHeight: 3.75rem;

/* Left Nav Layout */
$sidebarExpanded: 17.1875rem;
$streamMax: 23.75rem;

/* autocomplete */
$chipSearchWidth: 15.625rem;

/* Mobile Variables */
$sidebarHeight: 4.875rem;
$streamHeight: 3.75rem;

$tableBorderStyle: 0.0625rem solid #CFDADC;
$commentBorderSize: .1rem;

$tabHeight: 3.1rem;

/* mega menu */
$megaColor: white;
$megaOpacity: 0.3;

@mixin ellipsis($width: 'auto') {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  @if $width != 'auto' {
    width: $width;
  }
}

@mixin word-wrap() {
  hyphens: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1rem, $lineCount: 2, $bgColor: white){
  overflow: hidden;
  position: relative;
  text-align: justify;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  margin-right: -1rem;
  padding-right: 1rem;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    margin-top: 0.2rem;
    background: $bgColor;
  }
}

@mixin gmItem {
  padding: 0 !important;
  > button, > .toggle {
    display: none !important;
  }
  > div > span {
    font-size: .75rem;
  }
  > button, & > div, & > span {
    // flex: 0;
    margin: .4rem;
    position: relative !important;
  }
  .MuiTouchRipple-root {
    margin: 0;
  }
}
@mixin gmSection {
  .sidebar-divider {
    display: none;
    flex: 0 0 100%;
    height: 0;
  }
  > div {
    > * {
      &:not(.sidebar-divider) {
        justify-content: space-between;
        flex-basis: 20%;// TODO: move this into Sidebar and calculate flex basis
        display: flex;
      }
      > .gmItem {
        @include gmItem;
      }
    }
  }
}

@mixin loginField {
  .field {
    padding: .625rem;
    overflow: hidden;
    // Temporarily hide the input autocomplete background color
    // TODO: Highlight only the typeable region with the following color:
    // rgb(250, 255, 189)
    input:-webkit-autofill {
      box-shadow: inset 0px 0px 0px 50px white !important;
      -webkit-box-shadow: 0px 0px 0px 50px white inset !important;
    }
    input:-webkit-autofill:focus {
      box-shadow: 0px 0px 0px 50px white inset !important;
      -webkit-box-shadow: 0px 0px 0px 50px white inset !important;
    }
  }
}
@mixin loginRow {
  .row {
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: left;
    white-space: nowrap;
    .rowItem {
      padding: .125rem;
      margin: auto;
    }
    .verticalCenter {
      margin: auto 0;
    }
  }
}

html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html, body { height: 100%; width: 100%; }
html {
  font-size: 16px;
  font-family: Roboto !important;
  overflow: hidden;
  body {
    // height: 100vh;
    // width: 100vw;
    overflow: auto;
    #app, .app-container {
      height: 100%;
      width: 100%;
    }
    .app-container {
      position: relative;
    }
  }
}
/*
8px: .5rem
10px: .625rem
12px: .75rem
14px: .875rem
18px: 1.125rem
20px: 1.25rem
24px: 1.5rem
*/

.centered {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.controls {
  .vmoso_fonts {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.dateTraversalPicker {
  align-items: 'space-between';
  display: 'flex';
  flex-direction: 'row';
  .MuiInput-input {
    flex: 1;
    text-align: center;
  }
}

.ercList {
  height: 17rem;
  overflow: hidden;
}

.erc {
  display: flex;
  height: 0.5rem;
  flex-direction: row;
  align-items: center;
  .name {
    margin-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 10rem;
  }
  .date {
    margin-left: 0.5rem;
  }
}

.ercPopover {
  max-height: 50%;
  min-width: 12.5rem;
}

.error {
  color: #f44336;
}

// .grid-container {
//   height: 100%;
// }

.headerLeftIcon {
  font-size: 2.8125rem !important;
  color: white !important;
  text-align: center !important;
  padding: 0 .9375rem 0 0 !important;
}

.icon-button {
  height: 3rem !important;
  padding: .75rem !important;
  width: 3rem !important;
}

.mention_cke, .rmevtag_cke {
  color: #0089C5;
  cursor: pointer;
  display: inline;
  &.refme {
    color: #C3002F !important;
  }
}

.ReactModalPortal {
  .ril-toolbar {
    .searchBar {
      margin-right: .5rem;
      width: inherit;
      .MuiTextField-root {
        min-width: inherit;
      }
    }
    .searchMatches {
      display: inline;
      white-space: nowrap;
    }
    .ril__toolbarItem {
      font-size: 100%;
    }
  }
}

.searchBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  .selectedCount {
    flex-grow: 1;
    justify-content: flex-end;
    > span {
      color: #27A9E1;
      margin-left: 1.25rem;
    }
  }
  > div {
    flex: 2;
  }
  .searchBar {
    width: 25rem;
    .suggestions-container-open {
      .suggestion {
        .autocompleteUserAvatar {
          display: inline-block !important;
        }
      }
    }
  }
}

//menuItem
.super-admin {
  color: #FF0000 !important;
  &.exit > div > div > div:first-child {
    color: #CCC;
    line-height: .5rem;
    padding-bottom: .75rem;
  }
}
.super-admin-strap {
  color:white;
  background-color: #FF0000;
  font-size: .875rem;
  text-align: center;
  padding: .5rem;
}

.tabbar {
  height: $tabHeight !important;
  border-bottom-width: 0.0625rem;
  border-bottom-style: solid;
  button.item {
    border-radius: 0px;
    .vmoso_fonts {
      font-size: 1.5rem !important;
    }
    img {
      max-width: 2.2rem;
    }
    .arrow {
      .vmoso_fonts {
        font-size: 12px !important;
      }
    }
  }
}
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .tabbar {
    height: inherit !important;
  }
}
.toggleReadonly {
  display: flex;
  z-index: 1000;
}
.top-header {
  align-items: center;
  height: $headerHeight !important;
  margin: 0 !important;
  // max-height: $headerHeight !important;
  // min-height: $headerHeight !important;
  padding: 0 1rem !important;
  &.action {
    color: white;
  }
  &.creation-megatoolbar {
    background-color: rgb(2, 136, 209) !important;
  }
  > .content.megatoolbar {
    align-items: center !important;
    .icon-button {
      &:first-child {
        padding-left: 0 !important;
      }
    }
    .space-dropdown-icon {
      color: white !important;
      font-size: 1.5rem !important;
    }
    section {
      align-items: center !important;
      display: flex !important;
      Button {
        left: 0 !important;
        padding-left: 0 !important;
        right: 0 !important;
        top: 0 !important;
      }
      .headerTitle {
        font-size: 1rem !important;
      }
    }
  }
  .headerTitle {
    display: block;
    font-size: 1.125rem !important;
    line-height: 1.25rem !important;
    @include ellipsis();
    .entityDetails & {
      padding: .125rem 0 !important;
    }
  }
  .subTitle {
    font-family: Roboto;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1rem;
  }
  .actions {
    background-color: #FFF;
  }
}

.table {
  .header {
    border-bottom: $tableBorderStyle;
    border-top: $tableBorderStyle;
  }
  tr {
    border-bottom: $tableBorderStyle;
    th, td {
      font-size: .875rem !important;
      &.role > div {
        display: block !important;
      }
      &.isLast {
        div {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .tblActions {
            display: none;
            justify-content: flex-end;
            padding-right: 0.5rem;
          }
        }
      }
      .auxLine {
        color: grey;
        display: block;
        font-size: 0.75rem;
        font-weight: normal;
        line-height: normal;
        white-space: normal;
        z-index: 1000;
      }
      &.richtext img {
        max-width: 100%;
      }
    }
    &:hover {
      td.isLast {
        div {
          .tblActions {
            display: flex;
          }
        }
      }
    }
  }
}

.BKMheaders {
  .searchActions {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    margin-left: auto;
    top: 0.4rem;
  }
  .searchBar {
    position: relative;
    display: flex;
    left: 0;
    min-width: 12.5rem;
    right: 0;
    background-color: none;
    width: 20rem;
    top: 2.1rem;
    margin-right: 10rem;
    .suggestions-container {
      left: 2rem;
    }
    .searchActions {
      button.buttonCancelText {
        bottom: 0rem;
        left: 0.5rem;
        top: 0.7rem;
      }
      button.button-search {
        left: 3rem;
      }
    }
  }
}

.expandedHashHeader {
  background-color: transparent !important;
  border-bottom: .0025rem solid #CCC !important;
  height: 0.75rem !important;
  max-height: 0.75rem !important;
  min-height: 2.05rem !important;
  padding: 0rem 1rem !important;
}

.filter {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: Roboto;
  font-size: .875rem;
  font-weight: 300;
  flex-direction: row;
  > span {
    margin: .25rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > svg, > button {
    position: relative;
  }
}
.filterPopover {
  border: .0625rem solid #bbb;
  border-left: none;
  margin-left:-1rem;
  max-height: calc(100vh - 60px) !important;
  width: 15.625rem;
  .rightText {
    position: absolute;
    right: 40px;
  }
}
.selectedFilter {
  color: #0288d1 !important;
  border-left: 5px solid #0288d1 !important;
}
@mixin columns() {
  th.helpInfo, td.helpInfo {
    width: 4.5rem;
  }
  /*Css for checkboxes in chooser*/
  th.chooser, td.chooser {
    width : 2.75rem;
  }
  th.name {
    button {
      div {
        .vmoso_fonts {
          top: .3125rem;
        }
      }
    }
  }
  td {
    .listItemName {
      display: flex;
      align-items: center;
      @include ellipsis();
      &.space {
        span {
          padding-left: 0rem !important;
        }
      }
    }
  }
}

@mixin create-page {
  display: inline-flex;
  flex-direction: column;
  max-width: 90% !important;
  max-height: 90% !important;
  min-height: 90%;
  overflow: hidden;
}

.chooser > div {
  > div {
    @include create-page;
    // height: 100%;
    // max-height: 46rem !important;
    > div:not(.top-header) {
      overflow-y: hidden !important;
      padding: 0 !important;
    }
  }
  .list {
    height: 35rem;
    @include columns();
  }
}

.chooser.hasTabs > div {
  .list {
    height: 32rem;
  }
}

.chooser {
  .chooserContent {
    .searchBarContainer {
      .searchBar {
        .suggestions-container {
          .suggestions-input {
            height: 3rem !important;
          }
        }
      }
    }
  }
}

.entityCreate > div {
  > div {
    @include create-page;
    position: relative;
    > div:not(.top-header) {
      padding: 0 !important;
    }
    section {
      .page {
        padding: 0 1.5rem 1rem !important;
        min-height: 40rem;
      }
      .settingForm {
        padding: 0.5rem 1.875rem !important;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
// .entityCreate input[type="color"] {
// }

.versionTable {
  border: $tableBorderStyle;
  table {
    margin: 0 !important;
    width: 100% !important;
    @include columns();
  }
}

.mega {
  // border-right: .0625rem solid #ccc;
  overflow: auto;
  // padding: .75rem 1rem !important;
  padding: 0 0 !important;
  :hover {
    background-color: rgb(66, 132, 170);
  }
  .stream {
    :hover {
      background-color: white;
    }
  }
  .mega-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: .25rem .75rem;
    &.recentMode {
      padding-left: 1.75rem;
    }
    svg.MuiSvgIcon-root {
      color: $megaColor;
      background: rgba(0, 96, 152, 0.87);
    }
    .home {
      font-size: 1.3rem !important;
      padding-left: 0px;
      vertical-align: text-bottom !important;
    }
    .icon-button {
      &.uplevel {
        padding-left: 0px !important;
        width: 2rem !important;
      }
    }
    .last-crumb {
      color: $megaColor !important;
      opacity: 1 !important;
    }
    .path-separator {
      color: $megaColor;
      opacity: $megaOpacity;
    }
    .title-container {
      align-items: center;
      display: flex;
      max-width: 85%;
    }
    .recents {
      display: flex;
      flex-direction: column;
      .spacePath {
        font-size: .875rem;
        font-style: italic;
      }
    }
    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      &.crumb {
        color: $megaColor;
        opacity: $megaOpacity;
      }
    }
  }
}

.mega-empty {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

@mixin streamDetail {
  bottom: 1px;
  left: 3.75rem;
  padding: 0 1rem;
  position: absolute;
  right: 0;
}

.stream {
  //height: calc(100vh - 3.5rem) !important;
  border-right: .0625rem solid #ccc;
  .item {
    // height: 6rem !important;
    .titleWrap {
      align-items: flex-start;
      display: flex;
      flex: 1;
      justify-content: space-between;
      margin-right: 0.25rem;
      width: 100%;
      &.notif {
        width: calc(100% - 2.5rem);
      }
    }
    .title {
      @include ellipsis();
      color: #37474F;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.5rem;
      &.read {
        color: #78909C;
      }
    }
    &.read {
      color: #78909C;
    }
    .streamTile {
      padding: 1rem 1rem 0;
      &.specialTile {
        .box {
          border-bottom: 0rem !important;
        }
      }
      .box {
        border-bottom: .0625rem solid #CFDADC;
        /*height: 5rem;*/
        max-width: calc(100% - 2.75rem);
        // min-height: 6rem;
        overflow: hidden;
        padding-bottom: .375rem;
        .content {
          @include word-wrap();
          line-height: 1rem;
          // Comment out max-height to have flexible height tiles
          max-height: 2rem;
          overflow: hidden;
          + div {
            display: flex;
            justify-content: space-between;
            .deadline {
              display: block;
              &.due {
                color: #7cb342;
              }
              &.now, &.over {
                color: #c53e3e;
              }
            }
          }
        }
        .secondary {
          border: .0625rem solid #CFDADC;
          display: flex;
          justify-content: space-between;
          margin: 0.5rem 0;
          padding-bottom: 0.5rem;
          > .block-with-text {
            @include multiLineEllipsis(1rem, 3, white);
            > div {
              margin-right: .8rem;
            }
          }
        }
      }
      .inlineCommentBox {
        // Move up to cover the .box bottom border
        margin-top: -1px;
        .cke_contents {
          height: 50px !important;
        }
      }
      .streamDetails {
        @include streamDetail();
        align-items: center;
        background-color: white;
        display: flex;
        justify-content: flex-end;
        > * {
          margin-left: 1rem;
        }
        .badge {
          margin-left: 1.4rem;
        }
        .mark-list {
          margin-bottom: 0.5rem;
        }
        .moreMenu {
          margin-left: 0.4rem;
        }
      }
    }
  }
  .listItem {
    // height: 3rem !important;
    .listTile {
      padding: 0.3rem 1rem 0;
      margin-left: 0rem;
      .selectableAvatar {
        border: none;
        > div {
          height: 100% !important;
          width: 100% !important;
        }
      }
      .box {
        border-bottom: .0625rem solid #CFDADC;
        height: 3rem;
        margin-left: 0.6rem;
        .grouptype {
          color: #90A4AE;
          font-size: 0.875rem;
        }
      }
    }
  }
  .streamSubheader {
    color: #009bde !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    margin-top: 0.25rem;
    display: inline-block;
  }

  .contactStream {
    .listTile {
      .streamSubheader {
        padding-left: 0.2rem !important;
        width: 2rem !important;
        .letter {
          color:#009bde;
          font-size: 1.125rem;
          margin-top: 0.1rem;
          display: inline-block;
        }
      }
      .box {
        height: 2rem !important;
      }
    }
  }
  .item.selected,
  .listItem.selected {
    background-color: #E1E5E9 !important;
    &:hover {
      border-bottom-color: transparent;
    }
    .streamTile {
      .streamTileToolbarRow {
        background-color: #E1E5E9;
      }
      .streamDetails {
        background-color: #E1E5E9 !important;
      }
    }
  }
}

// layout overrides
.component-wrapper-entity,
.component-wrapper-entity > div,
.nested-container,
.row-container {
  display: flex;
  flex-direction: column;
  // max-height: 100%;
}
// display template wrapping with extra div TODO: should extra div be removed from API output instead?
.component-wrapper-entity > div { width: 100%; }
.component-wrapper-entitylist .stream { border-right: none; }
.component-wrapper-entitylist .homePage .streamContent .stream { height: 100%; }
// 90vh set as dialog height, 48px set as dialog title bar
.layout-dialog .entityDetails .entityComments { height: calc(90vh -  #{$headerHeight + $tabHeight} - 48px) !important}
.toggle-container .sidebar {
  width: 100%;
  &.expanded, &.docked {
    max-width: initial;
    min-width: initial;
  }
}
// .component-wrapper-menu {
//   width: 100%;
// }
.component-wrapper-menu .sidebar { height: 100%; position: relative }
.component-wrapper-login {
  .loginForm {
    flex-direction: column;
    text-align: center;
    a { text-decoration: none; }
    .buttons { margin: 1rem 0 }
    .cellHalf { padding: 0.625rem; width: 50%; }
  }
  @include loginField();
  @include loginRow();
}

#app {
  // display: flex;
  // justify-content: flex-start;
  .splashScreen {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 16rem;
    width: 12.5rem;
  }
  .splashScreenContainer {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
  }
}

#atlwdg-trigger {
  display: none;
}

.container {
  display: flex;
  height: 100vh;
  width: 100vw;
  .streamContent, .entityDetails {
    flex: 1;
    padding: 0.01rem;
  }
}
/*CSS for Online Status*/
.selectedStatus {
  border-left: 0.3rem solid #0087CF !important;
  span {
    color: #0087CF;
  }
}
.sidebar {
  height: 100vh;
  width: $gmMin;
  max-width: $gmMin;
  min-width: $gmMin;
  overflow: hidden;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  > .content {
    background-color: #546E7A;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 100%;
  }
  .MuiIconButton-root {
    padding: 0,
  }
  section {
    width: 100%;
    padding: 0 !important;
    /*> div {
      padding: 0 !important;
      width: 100%;
    }*/
    &.topButtons .gmItem {
      .vmoso_fonts {
        margin: 1.125rem .75rem .75rem !important;
      }
    }
    .gmItem {
      border: .625rem !important;
      display: flex !important;
      font-size: 1rem !important;
      line-height: 1rem !important;
      padding-bottom: 0;
      padding-left: 0;
      padding-top: 0;
      // &.user > div > div {
      //   padding-bottom: .3125rem !important;
      //   padding-top: .3125rem !important;
      // }
      > div {
        margin-right: .5625rem;
      }
      + div {
        display: none;
      }
      .brandingSeparator {
        color: white;
        font-size: .75rem;
        font-style: italic;
        text-align: center;
        padding-top: .375rem;
      }
      .vmoso_fonts {
        font-size: 1.5rem !important;
        height: 1.5rem !important;
        margin: .5625rem 0.75rem !important;
        width: 1.5rem !important;
      }
      .recents-menu-button {
        .vmoso_fonts {
          margin: 0 !important;
        }
      }
      svg path {
        fill: rgba(255, 255, 255, 0.54)
      }
      &.entity-create-button {
        height: 3rem;
        > div > div {
          padding-bottom: 1rem !important;
          padding-top: 1rem !important;
        }
      }
      &.sidebar-fab {
        .vmoso_fonts {
          font-size: 2rem !important;
          height: 2rem !important;
          line-height: 2rem !important;
          margin: 0 !important;
          width: 2rem !important;
          position: relative;
        }
      }
      .badge {
        .vmoso_fonts {
          margin: 0 !important;
        }
      }
    }
  }
  .zoneSection {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    .gmItem {
      .badge {
        left: .5625rem;
      }
    }
  }
  .statusItems, .customItems {
    .item {
      padding: .75rem 0 0 .75rem;
      width: 100%;
      display:flex;
      justify-content: flex-start;
      flex-grow: 1;
      .material-icons {
        color: #CCC !important;
        font-size: 1.875rem !important;
      }
      &.btn {
        padding: 0;
      }
    }
  }
  .customItems {
    border-top: .0625rem solid #CCC;
    border-bottom: .0625rem solid #CCC;
    width: 100%;
    flex-grow: 15;
  }
  &.expanded, &.docked {
    max-width: $sidebarExpanded;
    min-width: $sidebarExpanded;
    section .gmItem + div {
      display: block;
    }
    section .gmItem.recentItem {
      font-size: .875rem !important;
      line-height: .3125rem !important;
      height: 1.5rem !important;
      width: 100%;
      > div {
        height: 1.5rem;
        padding: 0 5.25rem !important;
        > span {
          font-size: .875rem;
          line-height: 1.5rem;
          @include ellipsis();
          width: calc(#{$sidebarExpanded} - 9rem);
        }
      }
      :hover {
        .vmoso_fonts {
          color: rgba(255, 255, 255, 0.54) !important;
        }
        .vmoso_fonts:hover {
          color: white !important;
        }
      }
      .vmoso_fonts {
        font-size: 1rem !important;
        height: 1rem !important;
        left: 3.55rem !important;
        width: 1rem !important;
        margin: .25rem !important;
      }
      .homePage .streamContent {
        flex-basis: 40%;
      }
    }
  }
  &.docked {
    position: inherit;
    ~ .megaContainer {
      margin-left: $sidebarExpanded;
      .list-spinner {
        margin-left: -$sidebarExpanded;
      }
    }
  }
  &.createMenu {
    overflow: initial,
  }
  &.overlayMenu {
    overflow: initial,
  }
  + .streamContent {
    margin-left: $gmMin;
  }
  ~ .megaContainer {
    margin-left: $gmMin;
    .list-spinner {
      margin-left: -$gmMin;
    }
  }
  &.expanded + .streamContent {
    z-index: -1;
  }
  &.docked + .streamContent {
    position: inherit;
    margin-left: inherit;
    z-index: inherit;
    width: calc(100vw * 0.3);
    .homePage .streamContent {
      flex-basis: 35%;
    }
  }
  .mobile-menu-button {
    display: none !important;
  }
}

.homePage {
  display:flex;
  justify-content: flex-start;
  // flex-wrap: wrap;
  //flex: 1;
  .entityDetails {
    flex: 1;
    height: 100vh;
  }
  .megaOverlay {
    background-color: $overlay-color;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-Index: 179;
  }
  .megaContainer {
    left: 0;
    position: absolute;
    top: 0;
    z-Index: 180;
  }
  .showfilterSection.streamContent {
    .stream {
      height: calc(100vh - #{$headerHeight + $tabHeight});
    }
  }
  .streamContent {
    /* flex-basis: 30%; */
    height: 100vh;
    width: 100%;
    /* max-width: $streamMax; */
    min-width: 25rem;
    /* width: calc(100vw * 0.35); */
    &.megaContainer, &.mega-creation {
      .suggestions-container {
        top: 0 !important;
      }
    }
    &.tabbar2 {
      .stream {
        height: calc(100vh - #{$streamHeight} - #{$tabHeight});
      }
      .stream.spinnerActive {
        height: calc(100vh - #{$streamHeight + 5} - #{$tabHeight});
      }
      .noResults.spinnerActive {
        height: calc(100vh - #{$streamHeight + 5} - #{$tabHeight});
      }
    }
    .content {
      > div {
        .searchBar {
          right: 6rem !important;
        }
      }
    }
    .streamDetails {
      > div {
        align-items: center;
        display: flex;
        &.associations {
          .entity-name {
            @include ellipsis();
            max-width: calc(#{$streamMax} - 8rem);
          }
        }
      }
      .mark > button, .moreMenu > button {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        height: 2.25rem !important;
        width: 2.25rem !important;
      }
      /* stream detail space menu */
      > div > div.space-list > button {
        position: absolute !important;
        right: .25rem !important;
        top: 1.5625rem !important;
      }
    }

    .streamItems {
      .MuiListItem-root {
        padding: 0,
      }
      .MuiListItemText-root {
        margin: 0,
      }
    }

    .streamTileBody {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }

    .streamTileToolbar {
      .streamTileToolbarRow {
        align-items: center;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &.buttons {
          background-color: transparent;
          justify-content: flex-end;
        }
      }
    }

    .mega {
      height: 100vh;
      &.megaheader {
        height: calc(100vh - #{$headerHeight}) !important;
        &.mega-hidden-stream {
          height: 0px !important;
        }
      }
    }
    .stream {
      height: calc(100vh - #{$streamHeight});
      > div {
        .noResults {
          color: #78909C;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.25rem;
          position: relative;
          margin: 2rem;
        }
      }
    }
    .stream.spinnerActive {
      height: calc(100vh - #{$streamHeight + 5});
    }
    .noResults.spinnerActive {
      height: calc(100vh - #{$streamHeight + 5});
    }
  } // .streamContent
} // .homePage

.entityDetails {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #FFF;
  // border-left: .0625rem solid #CCC;
  overflow: hidden;
  width: 100%;
  .summary {
    flex-flow: column nowrap;
    .header {
      border-bottom: .0625rem solid #CCC;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      position: relative;
      .details {
        display: flex;
        flex-flow: row nowrap;
        img {
          cursor: pointer;
        }
        .info {
          display: flex;
          flex-direction: column;
          padding-left: .75rem;
          .name {
            color: #27A9E1;
            margin-bottom: 0.5rem;
            font-weight: bold;
            cursor: pointer;
          }
          .time {
            font-size: .875rem;
            color:#757575;
          }
        }
        .actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
  }
  .list {
    height: 100%;
    position: relative;
    text-align: center;
    .searchBar {
      max-width: 500px;
      .suggestions-container {
        .suggestions-input {
          height: 3rem !important;
          width: 100% !important;
        }
      }
    }
    .actions {
      align-items: center;
      display: inline-flex;
      float: right;
      justify-content: space-between;
      position: absolute;
      right: 1rem;
      .btn-chooser-add {
        height: 3rem !important;
        width: 3rem !important;
        padding: .5rem !important;
        .material-icons {
          font-size: 2rem !important;
        }
      }
    }
    table {
      thead {
        .header {
          border-bottom: .0625rem solid #CCC;
        }
      }

      tbody {
        tr {
          td {
            .listItemName {
              display: flex;
              align-items: center;
              @include ellipsis();
              .badge {
                span {
                  padding-left: inherit;
                }
              }
            }
          }
        }
      }
    }
    .section-add {
      text-align: center;
      border-bottom: .0625rem solid #CCC;
      position: relative;

      .add {
        position: absolute !important;
        right: 0.5rem;
        top: 0.5rem;

        .material-icons {
          color: #27A9E1 !important;
        }
      }
    }
  }
  .entityComments {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - #{$headerHeight + $tabHeight}) !important;

    .comments {
      padding: .5rem .5rem 0 .5rem;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 30px;
      overflow: auto;
      // height: 60vh !important;
      // margin-bottom: 2.5rem !important;
      .comment {
        .circle {
          background-color:#BCBCBC;
          border-radius: .25rem;
          display: block;
          height: .5rem;
          left: 2.3rem;
          position: absolute;
          width: .5rem;
        }
        .comment-box {
          font-family: Roboto;
          &.notification-box {
            display: flex;
            justify-content: center;
            margin: auto;
            max-width: 75%;
            .info {
              .comment-content {
                color: #37474F;
                justify-content: center;
                text-align: center;
                &.highlight {
                  background-color: inherit;
                  border: 0;
                }
                &.content-self {
                  background-color: inherit;
                  border: 0;
                }
                &.content-pending {
                  background-color: inherit;
                  border: 0;
                }
                &.content-read {
                  background-color: inherit;
                  border: 0;
                  font-weight: normal;
                }
                &.content-unread {
                  background-color: inherit;
                  border: 0;
                  font-weight: bold;
                }
                &.content-new {
                  background-color: inherit;
                  border: 0;
                }
              }
            }
          }
          > img {
            float: left;
            margin-top: .5rem;
          }
          .avatar {
            cursor: pointer;
            float: left;
            margin-top: .5rem;
          }
          .info {
            margin-left: 3rem;
              mark.selectMark {
                background-color: #F0B3BA;
              }
            .header {
              display: flex;
              flex-wrap: wrap;
              font-size: .75rem;
              justify-content: space-between;
              margin: 0 0.75rem 8px 0.75rem;
              .left {
                align-items: flex-end;
                display: flex;
                justify-content: space-between;
                margin-right: .5rem;
                position: relative;
                > div {
                  line-height: normal;
                }
                .bullet {
                  color: #90A4AE;
                  display: inline-block;
                  font-size: 1rem;
                  line-height: .75rem;
                  margin-right: .5rem;
                }
                .seq {
                  color: #009BDE;
                  display: inline-block;
                  font-weight: bold;
                  font-size: .75rem;
                  margin-right: .5rem;
                }
                .name {
                  color: #90A4AE;
                  display: inline-block;
                  margin-right: .5rem;
                }
                .date {
                  color: #90A4AE;
                  display: inline-block;
                  font-size: .75rem;
                  // margin-right: .5rem;
                  &.pending {
                    color: #c3002f;
                  }
                }
              }
              .right {
                align-items: flex-end;
                display: flex;
                justify-content: space-between;
                margin-left: .5rem;
                position: relative;
                vertical-align: baseline;
                white-space: nowrap;
                > div {
                  line-height: normal;
                }
                .read {
                  color: #90A4AE;
                  float: right;
                  font-size: .75rem;
                }
              }
            }
            .comment-content {
              @include word-wrap();
              border-radius: .625rem;
              color: #37474F;
              display: flex;
              font-size: .875rem;
              overflow-x: auto;
              user-select: text;
              * {
                line-height: 1.3rem;
                margin: 0;
                padding: 0;
                ol, ul {
                  margin-block-start: 1rem;
                  margin-block-end: 1rem;
                  padding-inline-start: 40px;
                }
              }
              > div img {
                max-height: 400px;
                max-width: 400px;
                vertical-align: top;
              }
              > div video {
                height: 360px;
                // width: 100%;
                // max-width: 400px;
                object-fit: fill;
              }
              a, a:visited {
                color: #009BDE;
                text-decoration: none !important;
              }
              pre {
                white-space: pre-wrap;
                word-wrap: break-word;
              }
              p {
                color: #37474F;
                font-size: .875rem;
                margin-top: 1em;
                margin-bottom: 1em;
              }
              > div table {
                border: 1px solid #CFD8DC;
                width: 100%;
                tr {
                  border-bottom: 1px solid #CFD8DC;
                  td {
                    padding: 7px;
                  }
                }
              }
              &.content-self {
                border: $commentBorderSize solid transparent;
                background-color: #FFF0D5;
              }
              &.content-pending {
                border: $commentBorderSize solid transparent;
                background-color: #f7bfba;
              }
              &.content-read {
                border: $commentBorderSize solid #CFD8DC;
                background-color: #FFFFFF;
              }
              &.content-unread {
                border: $commentBorderSize solid transparent;
                background-color: #ECEFF1;
              }
              &.content-new {
                border: $commentBorderSize solid transparent;
                background-color: #E1F5FE;
              }
              &.highlight {
                border: $commentBorderSize solid #FFC970 !important;
              }
              &.content-pending.highlight {
                border: $commentBorderSize solid #c3002f !important;
              }
              .refMe {
                [refme=true] {
                  color: #C3002F!important;
                }
              }
            }
            &.has-reactions {
              margin-bottom: 1rem;
              .comment-content {
                padding-bottom: 1.5rem !important;
              }
            }
          }
        }
      }
      .noResults {
        color: #78909C;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        font-size: 1.25rem;
        position: relative;
      }
    }
    .bot-menu {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 1rem;
    }
    .permissionMessage {
      color: #ff3333;
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .comment.parent {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 2rem;
        left: 2.5rem;
        height: 100%;
        border-style: solid;
        border-width: 1px;
        border-color: #BCBCBC;
      }
    }
    .comment.child {
      padding-left: 3.125rem;
    }
    .comment.child:not(.last) {
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 2.5rem;
        height: 100%;
        border-style: solid;
        border-width: 1px;
        border-color: #BCBCBC;
      }
    }
    .comment-cfsresults {
      font-size: 12px;
      color: #8c8c8c;
      line-height: 18px;
      padding: 0rem 0rem 0.5rem 1.5rem;
    }
    .comment-cfsresults.inside {
      padding: 0rem;
    }
    .switchBoard {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      height: 6vh;
      .psbList {
        margin-left: 0.5rem;
      }
      .psbMoreMenu {
        margin-right: 0.5rem;
      }
    }

    .switchBoardMoreMenuPopover {
      max-height: 50%;
    }
  }
  .mobile-back-button {
    display: none !important;
  }
}

.app-modal {
  .entityDetails {
    border: solid 1px #111;
    .entityComments {
      // window, modal inset top/bottom, padding, header, editor
      max-height: calc(100vh - 80px - 40px - 90px - 200px);
      .comments {
        margin-top: 0;
        padding: 0;
      }
    }
  }
  .simple-editor {
    height: 200px;
    padding: 10px;
  }
}

.intake-img-holder img {
  margin: 10px 0;
  max-width: 100%;
}

.entityDialog {
  > .MuiPaper-root {
    height: calc(100% - 64px);
    .MuiDialogContent-root {
      padding: 0;
    }
  }
}

.container {
  .entityDetails {
    height: 100%;
    .top-header {
      padding: 0 0 0 1rem !important;
      > .content {
        align-items: flex-start !important;
        overflow-x: hidden;
        > div:not(.badge) {
          overflow: hidden;
          > section {
            overflow: hidden;
          }
        }
      }
      > .searchActions {
        display: none;
        position: absolute;
        z-index: 2;
      }
      > .searchBar {
        display: flex;
        height: 3rem;
        left: 0 !important;
        min-width: 12.5rem;
        padding: 0px 3rem;
        padding-left: 0 !important;
        position: absolute;
        width: 100%;
        z-index: 101;
        .suggestions-container {
          margin-top: .25rem;
          top: -.5rem;
          .suggestions-input {
            display: flex !important;
            justify-content: center;
            > div:first-child {
              bottom: auto !important;
            }
          }
        }
        .searchActions {
          >button.buttonCancelText {
            bottom: 0px !important;
            >div {
              span.vmoso_fonts {
                font-size: 0.9rem !important;
                color: #ECEFF1 !important;
              }
            }
          }
          >button {
            padding: 0rem !important;
          }
          .countActions {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            font-size: .875rem;
            justify-content: space-between;
            position: absolute;
            right: 4.7rem;
            top: 0.7rem;
            height: 1.5rem;
            white-space: nowrap;
            > button {
              width: 1.5rem !important;
              height: 1.5rem !important;
              padding: 0rem !important;
              > div {
                display: flex;
                position: relative;
              }
            }
          }
        }
      }
      .filter {
        height: 1.125rem;
      }
      .actions {
        .searchActions {
          > button {
            > div {
              > span.vmoso_fonts {
                color: rgb(144, 164, 174) !important;
              }
            }
          }
        }
      }
    }
    /* .list {
      .searchBar {
        left: 25%;
        .suggestions-container {
          .suggestions-input {
            height: 3rem !important;
            width: 100% !important;
          }
        }
      }
      .actions {
        align-items: center;
        display: inline-flex;
        float: right;
        justify-content: space-between;
        margin-right: 1rem;
      }
    } */
  }
}

.selectableAvatar {
  border: solid .125rem;
  + span {
    > .vmoso_fonts {
      font-size: .75rem !important;
      height: .75rem !important;
      width: .75rem !important;
    }
  }
  .associations & .vmoso_fonts {
    height: 1rem !important;
    margin: .125rem !important;
    width: 1rem !important;
  }
  .listItemName &, .avatar-item & {
    align-items: center;
    border: none;
    display: inline-flex !important;
    height: 2rem !important;
    justify-content: center;
    width: 2rem !important;
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
}
.avatar-item > div > div > .vmoso_fonts {
  margin-top: .5rem !important;
}

.material-icons.read {
  color: #27A9E1 !important;
}

.material-icons.pin {
  color: #27A9E1 !important;
}

.material-icons.important {
  color: #CCC !important;
}

.material-icons.favorite {
  color: #FECF0D !important;
}

.material-icons.follow {
  color: #CCC !important;
}

.material-icons.rate {
  color: #CCC !important;
}

.material-icons.rate.up {
  color: #7CB342 !important;
}

.material-icons.rate.down {
  color: #B73A21 !important;
}

.material-icons.hide {
  color: #CCC !important;
}

.reactions {
  z-index: 100;
  &.shared {
    bottom: 1rem;
    margin-left: 10px;
    position: relative;
    > .btn {
      font-size: 1.25rem;
      height: 2rem !important;
      min-height: 2rem !important;
      min-width: 2rem !important;
      padding: 0 4px;
      width: auto !important;
      &.my {
        font-weight: bold;
      }
      .vmoso_fonts {
        font-size: 1rem !important;
      }
    }
    .count {
      color: rgb(120, 144, 156);
      font-size: .875rem;
    }
  }
  > .btn {
    background-color: white;
    -webkit-box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.2);
    box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.2);
    font-size: 1.75rem;
    height: 3rem !important;
    margin-right: .25rem;
    min-height: 3rem !important;
    width: 3rem !important;
    &.has-count {
      border-radius: 1.5rem;
      padding: 0 .5rem;
    }
    &:last-child {
      margin-right: 0;
    }
    &.my {
      -webkit-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.4);
    }
  }
}

.inputLabel {
  color: #78909C;
  position: relative;
  margin-top: 0.5rem;
  font-size: 16px;
}
.inputLabel.float {
  transform: scale(0.75);
  transform-origin: left;
}

/* chip auto complete search bar styles */
.chip-auto-complete {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .chips {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 28px;
    .chip svg {
      display: none !important;
    }
    .chip:hover:not(.readonly) {
      svg {
        display: block !important;
      }
    }
    .searchBar {
      display: flex;
      width: $chipSearchWidth;
    }
  }
  .rulers {
    margin: 8px 0 9px 0;
    position: relative;
    hr {
      border-bottom: $tableBorderStyle;
      border-left: none;
      border-right: none;
      border-top: none;
      width: 100%;
      margin: 0;
      position: absolute;
      top: 0;
    }
    hr.animate {
      border-bottom: .125rem solid #009BDE;
      transform: scaleX(0);
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }
    hr.animate.focus {
      transform: scaleX(1);
    }
  }
}

/* Content Name Resolution Dialog */
.content-name-resolution {
  color: #000000;
  p.title {
    font-size: 1.6rem;
    line-height: 1.2rem;
  }
}

/* Check Existing Chats Dialog */
.chatSelectionContainer {
  color: #000000;
  display: flex;
  flex-direction: column;
  .countActions {
    background-color: #e8e8e8;
    flex: 0;
    margin: -1.5rem;
    z-index: 1;
  }
  .formRow {
    overflow-y: auto;
  }
  p.newChatDescription {
    flex: 0;
  }
  p.newChatHeading {
    flex: 0;
    font-size: 1.6rem;
    line-height: 1.2rem;
  }
  .list {
    border: $tableBorderStyle;
    table {
      margin-top: 0.2rem;
      background-color: transparent !important;
      @include columns()
    }
    .table {
      tbody {
        tr {
          border-bottom: none !important;
        }
      }
    }
  }
}

/* search bar styles */
.searchBar {
  align-items: center;
  background-color: white;
  display: inline-block;
  position: relative;
  width: 50%;
  .searchActions {
    align-items: center;
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    > button.buttonCancelText {
      width: 1.5rem !important;
      height: 1.5rem !important;
      padding: 0rem !important;
      bottom: 0.3rem;
      >div {
        span.vmoso_fonts {
          font-size: 0.9rem !important;
          color: #ECEFF1 !important;
        }
      }
    }
    .material-icons {
      color: #AAA;
    }
  }
}

.streamContent {
  .searchBar {
    width: 100%;
    height: $headerHeight;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-top :.0625rem solid #AAA !important;
    border-bottom: .0625rem solid #AAA !important;*/
    .material-icons.close {
      color: #AAA !important;
    }
  }
  .top-header {
    color: #fefefe;
    position: relative;
    z-index: 10;
    &.space-megatoolbar {
      background-color: #00314D !important;
      .headerTitle {
        display: flex;
        align-items: center;
        max-width: 16rem;
        .space-title {
          @include ellipsis();
        }
      }
      .stream-toolbar {
        position: initial !important;
      }
    }
    > .stream-toolbar {
      .icon-button {
        width: auto !important;
        .vmoso_fonts {
          font-size: 1.5rem !important;
        }
        &.pinned-on {
          opacity: 1;
        }
        &.pinned-off {
          opacity: .5;
        }
        // &:last-child {
        //   padding-right: 0 !important;
        // }
      }
    }
    > div {
      .searchBar { /*Search bar active in header*/
        background-color: white;
        border-bottom: .0625rem solid #CCC !important;
        border-right: .0625rem solid #CCC;
        bottom: 0;
        display: flex;
        left: 0;
        min-width: 12.5rem;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index:100;
        .suggestions-input {
          display: flex !important;
          justify-content: center;
          > div:first-child {
            bottom: auto !important;
            top: 0;
          }
        }
        .suggestions-container {
          top: -.5rem;
        }
        .suggestions-list .menuItemLabel > span {
          max-width: 17rem;
        }
        .searchActions {
          > button.buttonCancelText {
            bottom: 0px !important;
          }
        }
        .filterSection {
          background-color: white;
          border-bottom: .0625rem solid #CCC !important;
          min-width: 12.5rem;
          display: flex;
          position: absolute;
          /* padding: 0rem 3rem;*/
          top: 3.8rem;
          border-right: .0625rem solid #CCC;
          z-index: 10;
          left: 0rem;
          right: 0rem;
          .buttonArrowDown {
            position: absolute;
            right: 0;
            top: 0;
          }
          .chips {
            align-items: center;
            flex-direction: row;
            margin-left: 1rem;
            .chip {
              align-items: center;
              span {
                margin-top: 0.3rem;
              }
              svg {
                display: none !important;
              }
            }
            .chip:hover:not(.readonly) {
              svg {
                margin-top: 0.3rem;
                display: block !important;
              }
            }
          }
        }
      }
    }
    .space-header-left {
      align-items: center;
      display: flex;
      flex-direction: row;
      .icon-button {
        &:first-child {
          padding-left: 0 !important;
        }
      }
    }
    /* Css to expand top-header when filter section UI is added */
    &.showfilterSection {
      padding: 0.35rem !important;
      max-height: $headerHeight + $tabHeight !important;
      height: $headerHeight + $tabHeight !important;
      align-items: flex-start;
    }
    > .content {
      overflow: hidden;
      > section {
        overflow-x: hidden;
      }
    }
    > div {
      .searchBar {
        background-color: white;
        border-bottom: .0625rem solid #CCC !important;
        border-right: .0625rem solid #CCC;
        left: 0px;
        min-width: 12.5rem;
        padding: 0px .9375rem;
        right: 0px;
        z-index: 100;
        > button {
          > div {
            > .vmoso_fonts.close {
              right: .5rem;
            }
          }
        }
        .searchActions {
          > button.buttonCancelText {
            bottom: 0px !important;
          }
        }
        .suggestions-container {
          right: .5rem;
        }
      }
    }
    .space-header-left {
      align-items: center;
      display: flex;
      flex-direction: row;
      .icon-button {
        &:first-child {
          padding-left: 0 !important;
        }
      }
    }
  }
}

$onboardingDialogHeight: calc(80vh - 120px);
.dialog-content {
  .dialog-body {
    border: none !important;
    .onboarding-content {
      display: flex;
      flex-direction: column;
      height: $onboardingDialogHeight !important;
      min-height: $onboardingDialogHeight !important;
      max-height: $onboardingDialogHeight !important;
      .body-wrap {
        .background-image {
          background-position: center;
          background-repeat:no-repeat;
          background-size: contain;
          height: 100%;
        }
      }
      .feedback {
        bottom: 70px;
        min-height: 30px;
        position: absolute;
      }
      .settings-profile {
        section.settingForm {
          &:first-child {
            float: left;
            width: 200px;
            > div {
              display: none !important;
            }
            > span {
              z-index: 1000;
            }
          }
        }
        div.settingForm > .formRow {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 0;
          position: relative;
          > .formCol {
            margin-right: 1.25rem;
            width: 100% !important;
            + div {
              margin: 0 !important;
              position: absolute !important;
              right: 10px;
              top: 40px;
            }
          }
          &:nth-child(-n+2) {
            > .formCol {
              margin-right: 0;
              width: calc(100% - 200px) !important;
            }
          }
          .aclText {
            color: #303030;
            display: block;
            font-size: 14px;
            text-align: right;
            width: 150px;
          }
        }
      }
      .vmoso.page {
        .section {
          .formRow {
            margin-bottom: 0;
            .formCol {
              width: 100% !important;
            }
          }
        }
        div.section:not(:first-child) {
          > .section-title {
            margin-bottom: 0rem !important;
            margin-top: 2rem !important;
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    > div > div.btn {
      margin-top: 40px;
      margin-right: 20px;
    }
  }
}
/* data-driven page layouts */
.vmoso.page {
  > .section:not(.api-onboarding) {
    display: flex;
    flex-direction: column;
    &.api-settings {
      width: 100%;
      > .section-title {
        margin: 0 !important;
        color: rgb(2, 136, 209) !important;
        &:hover {
          cursor: pointer;
        }
        > button {
          height: 1rem !important;
          padding: 0 !important;
          width: 1rem !important;
          .vmoso_fonts {
            color: rgb(2, 136, 209) !important;
            font-size: 1rem !important;
          }
        }
      }
    }
    &.api-show {
      > .content {
        display: block;
      }
    }
    &.api-hide {
      > .content {
        display: none;
      }
    }
    .section-title {
      align-items: center;
      color: $vmoso-color !important;
      display: flex;
      margin: 1rem 0 0 0 !important;
      &:hover {
        cursor: pointer;
      }
      > button {
        height: 1rem !important;
        margin-right: .2rem !important;
        width: 1rem !important;
        .MuiIcon-root {
          font-size: 1rem !important;
        }
        .vmoso_fonts {
          color: $vmoso-color !important;
          font-size: 1rem !important;
        }
      }
    }
  }
  &.stacked-wide {
    > .section {
      background-color: rgb(247, 252, 255);
      border-color: rgb(2, 136, 209);
      border-style: solid;
      border-width: 0.0625rem;
      margin-bottom: 1rem;
      padding: 1rem;
      > .section-title {
        color: rgb(2, 136, 209);
      }
      > .content {
        display: flex;
        flex-direction: row;
        > div {
          margin-right: 1rem;
        }
        &.col-1 > div {
          width: 100%;
        }
        &.col-2 > div {
          width: 50%;
        }
        &.col-3 > div {
          width: 33%;
        }
      }
    }
  }
}

_:-ms-fullscreen, :root .dialog-content .dialog-body .onboarding-content .feedback {
  bottom: 120px;
}

.field-inner-wrapper {
  display: flex;
  flex-direction: column;
  .actions {
    bottom: .75rem;
    display: flex;
    padding: 0 .3125rem;
    right: 0;
    > * {
      margin: 0 .3125rem !important;
    }
  }
  .error {
    font-size: 12px;
  }
}

.page, .settingForm {
  .formRow {
    margin-bottom: 0;
    &.api-visibility {
      margin-top: 1rem;
    }
    &.api-updateVersion .formCol {
      display: flex;
      > div {
        width: auto !important;
        margin-right: .5rem;
      }
    }
    .datetime {
      display: flex;

      .date {
        flex-grow: 1;
      }
      .time {
        flex-grow: 1;
      }
    }
    .floatingLabel {
      line-height: 22px;
      pointer-events: none;
      user-select: none;
      color: #78909C;
      font-size: 12px;
    }
    .MuiFormControl-root {
      justify-content: flex-end;
      margin-bottom: .875rem;
      margin-top: .875rem;
    }
    .suggestions-input {
      margin: 0;
      width: 100% !important;
    }
    > .recurrenceRule {
      .MuiFormControl-root {
        margin-top: 0;
      }
    }
  }
  .page-menu {
    height: 2.25rem;
    .page-menu-inner {
      float: right;
    }
  }
}

.settings {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100vw;
  .settings-body {
    height: calc(100vh - #{$headerHeight + $tabHeight} - 1px);
    overflow: scroll;
  }
  .settingForm {
    padding: .5rem 6.25rem 1rem;
    .avatar {
      border: .125rem solid #27a9e1;
      cursor: pointer;
      position: relative;
    }
    .aclText {
      color: #303030;
      display: block;
      font-size: 14px;
      text-align: right;
      width: 150px;
    }
    .formRow {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .label {
        margin: auto 0;
      }
    }
    .formRow > div {
      display: flex;
    }
    .formRow.api-manage, .formRow.api-upgrade {
      display: block !important;
      // text-align: center !important;
      div {
        box-shadow: none !important;
        button {
          border-radius: 18px !important;
          padding: 0 2rem !important;
        }
      }
    }
    .formRow.api-date_time_example_label {
      color: #0288d1;
      float: left;
      padding-right: 10px;
    }
    .formRow.api-date_time_example {
      float: left;
      padding-right: 10px;
    }
    .formCol {
      box-sizing: border-box;
      flex-grow: 20 !important;
      margin-right: 1.25rem;
      // does this need api prefix?
      &.flex-row {
        display: flex;
        > div {
          width: auto;
          margin-right: .5rem;
        }
      }
    }
    .notifications {
      .hidden {
        display: none;
      }
    }
  }
  .headerTitle, .subTitle {
    color: white !important;
  }
  .integration {
    .integrationHeader {
    height: 5.9375rem !important;
    border-bottom: .0625rem solid rgb(2, 136, 209);
    background-color:#fefefe !important;
      .sectionHeader {
        height: 5rem;
        display: flex;
        justify-content: space-between;
        width:100%;
        div {
          section {
            .integHeaderTitle {
              color: black;
              line-height: 35px !important;
              font-size: 1.5rem !important;
              top: 0px;
            }
            .integHeaderTitle.Zones {
              top: 1rem;
            }
            .integSubTitle {
              color: black;
              padding-top: .125rem !important;
            }
          }
        }
      }
    }
  }
  .settings-online-status-inner {
    display: flex;
    margin-bottom: -10px;
  }
  .settings-online-status-label {
    color: #303030;
    cursor: default;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
  }
  .settings-profile {
    .formRow {
      flex-wrap: inherit;
    }
    .privacy-icon {
      align-self: flex-end;
    }
  }
  .zone-toolbar {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: 1rem 5rem;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 2rem;

  .card {
    flex: 0 1 calc(20% - 1rem);
    margin-bottom: 2rem;
    border: .0625rem solid lightgrey;
    box-shadow: 0 .375rem .0625rem -.0625rem #e8e5e5;

    img {
      display: block;
      border: 0;
      width: auto;
      height: 10rem;
    }

    a {
      color: black;
      text-decoration: none;
    }

    a:hover {
      box-shadow: .1875rem .1875rem .5rem hsl(0, 0%, 80%);
    }

    .card-content {
      padding: 0.5rem;
    }

    .card-content h2 {
      margin-top: 0;
      margin-bottom: .5rem;
      font-weight: normal;
      font-size: 90%;
      color: #333;
    }

    .card-content p {
      font-size: 80%;
      color: #757575;
    }

    .thumbnail {
      .placeholder {
        background: #FECF0D;
        color: #B39109;
        padding: 0.5rem;
        position: relative;
        width: 100%;
        height: 7rem;

        .label {
          position: absolute;
          bottom: 0.5rem;
        }
      }
    }
  }
}

.bot-list {
  padding: 0 !important;
  > div {
    border-bottom: solid 1px #CFDADC;
  }
  .title {
    font-size: .875rem !important;
  }
  > .title {
    color: black !important;
    font-weight: bold !important;
    padding: 0 !important;
    line-height: 1.75rem !important;
  }
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #303030 !important;
    font-size: .75rem !important;
    padding: .375rem 0 !important;
    .content {
      > .title {
        color: #0288D1 !important;
      }
    }
    > img {
      max-width: 2rem !important;
      max-height: 2rem !important;
      width: 2rem !important;
      height: 2rem !important;
    }
  }
}

/* custom components */
@mixin custom-divider {
  width: 100%;
  text-align: center;
  border-bottom: .0625rem solid;
  line-height: 0.1rem;
  margin: .4375rem 0 .4375rem;
  span {
    padding:0 .625rem;
  }
}

.sidebar-divider {
  @include custom-divider;
  & {
    border-bottom-color: rgb(144, 164, 174);
    margin: .5rem 0;
    height: 0.1rem;
  }
  span {
    visibility: hidden;
    background: #546E7A;
    color: rgb(144, 164, 174);
    font-size: .8rem;
    .expanded &, .docked & {
      visibility: visible;
    }
  }
}

.filter-custom-divider {
  @include custom-divider;
  & {
    border-bottom-color: #90a4ae;
    font-size: 1.5rem;
  }
  span {
    background: #fff;
    color: #90a4ae;
    padding: 1.25rem .625rem;
  }
}

.filter-divider {
  @include custom-divider;
  & {
    border-bottom-color: rgb(144, 164, 174);
    margin: 0;
  }
}

.fieldGroup > .content .progress-field-container {
  width: 100%;
}

/* loading UI */
.progress:not(.fieldGroup) {
  position: absolute !important;
  &.formRow {
    position: inherit !important;
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0.5rem 0 !important;
      > * {
        margin: .25rem 0 !important;
      }
      > span {
        color: rgb(67, 87, 97);
        font-size: 0.75rem;
      }
    }
  }
}

.loading .row {
  align-items: flex-start;
}

.loading .button {
  background-color: #bdced6;
  height: 1.5rem;
  width: 1.5rem;
}

.sidebar.loading {
  background-color: rgba(189, 206, 214, 0.5);
  .row {
    border-bottom: .0625rem solid #bdced6;
    flex-direction: column;
    padding: .625rem 0px;
    width: 100%;
    .button {
      margin: .625rem 0px;
      min-height: 1.5rem;
    }
    .icon {
      border-radius: 1.25rem;
      background-color: #bdced6;
      min-height: 2.5rem;
      margin: .625rem 0px;
      width: 2.5rem;
    }
  }
  .row:nth-child(3) {
    flex-grow: 1;
    .button {
      background: none;
    }
  }
}

.top-header.loading {
  background-color: rgba(189, 206, 214, 0.5);
  .row {
    .content.left {
      align-items: flex-start;
      display: flex;
      width: 50%;
        .icon {
          opacity: 1;
          background-color: #bdced6;
          margin-right: 4%;
        }
        .content {
          display: flex;
          flex-direction: column;
          height: 3.5rem;
          justify-content: flex-start;
          width: 75%;
          .bar {
            opacity: 1;
            background-color: #bdced6;
            height: 1.25rem;
            width: 100%;
            margin-bottom: .25rem;
          }
          .tab {
            opacity: 1;
            background-color: #bdced6;
            height: .875rem;
            width: 33%;
          }
        }
      }
      .content.right {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        width: 50%;
        .button {
          margin-left: 4%;
        }
      }
  }
}

.streamContent {
  .top-header {
    color: #fefefe;
  }
  .top-header.loading .row .content.left {
    .content {
      justify-content: flex-start;
      // .bar, .tab {
      //   height: 1.375rem;
      // }
    }
  }
}

.stream.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - $headerHeight);
  overflow: hidden;
  .row {
    align-items: start;
    background: none;
    border-bottom: .0625rem solid #bdced6;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 6.25rem;
    opacity: 0.5;
    padding: .9375rem 0px;
    width: 90%;
    .icon {
      background-color: #bdced6;
      border-radius: 1.375rem;
      height: 2.75rem;
      width: 2.75rem;
    }
    .content.right {
      width: 100%;
      .bar {
        background-color: #bdced6;
        height: .9375rem;
        margin: .1875rem 0px 0px .625rem;
        width: 80%;
      }
      .bar:nth-child(1) {
        height: 1.25rem;
        margin-bottom: .9375rem;
      }
      .bar:nth-child(3) {
        width: 85%;
      }
    }
  }
}

/* TODO: pull the loading styles out of this block */
.entityDetails {
  .loading .icon {
    height: 2rem;
    width: 2rem;
  }
  .tabbar.loading {
    background-color: rgba(189, 206, 214, 0.5);
    .row {
      align-items: center;
      height: 100%;
      justify-content: space-around;
      width: 100%;
    }
  }
  .entityBody.loading {
    opacity: 0.5;
    > .row:nth-child(1) {
      justify-content: flex-end;
      padding-right: 1.5625rem;
    }
    .row {
      border-bottom: solid 0.002rem #bdced6;
      justify-content: flex-start;
      min-height: 3.125rem;
      padding-left: 1.25rem;
      .icon {
        margin-right: 2%;
      }
      .content {
        display: flex;
        height: 1.25rem;
        justify-content: space-between;
        width: 75%;
        .tab {
          background-color: #bdced6;
          width: 18%;
        }
      }
    }
    .table {
      .row {
        border: none;
        .icon {
          background-color: #bdced6;
          border-radius: 2.125rem;
        }
        .content {
          align-items: center;
          border-bottom: solid 0.002rem #bdced6;
          min-height: 3.125rem;
          width: 75%;
          .bar {
            background-color: #bdced6;
            height: 1.25rem;
            width: 100%;
          }
        }
      }
    }
  }

  .entityBodySettings {
    overflow-y: auto;
    flex-grow: 1;
    // height: 100%;
    // margin-right: 20px;
    .page {
      margin: 1rem;
    }
  }

  .entitySettings {
    input[type="color"] {
      padding: 2.4rem 0 1.3rem 0 !important;
    }
  }
  .fileVersionForm {
    padding: .625rem 2.5rem;
    .list {
      border: $tableBorderStyle;
      table {
        margin-top: 0.2rem;
        background-color: transparent !important;
      }
    }
  }

  .summary {
    .content {
      padding: .625rem;
    }
  }
}

.traversal {
  .entityComments {
    height: calc(100vh - 10.5rem) !important
  }
}

.mapper-container {
  .entityComments {
    height: calc(100vh - 10.5rem) !important
  }
}

/* when search Bar is expanded in map mode*/
.expanded-mapper-container {
  .entityComments {
    height: calc(100vh - 12.5rem) !important
  }
}

.mapper-icon {
  color: white !important;
  font-size: 2rem !important;
  padding: 0 1rem 0 0 !important;
}

.history-bar-item {
  @include ellipsis();
  display: block;
}

.overlay {
  background-color: $overlay-color !important;
}

.infoDialog {
  .info-actions {
    padding: 0.75rem 1rem !important;
  }
  .info-action {
    color: $user-color !important;
    min-width: 0 !important;
  }
  .info-description {
    padding: 0rem 1rem .75rem;
  }
}

.dosDialog {
  .choice {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1.05rem;
    padding: 0.35rem 0rem;
    &.chkbox {
      .vmoso_fonts {
        margin-left: 3.33rem !important;
      }
    }
    .vmoso_fonts {
      color: rgba(0, 0, 0, 0.65) !important;
      font-size: 1.75rem !important;
      margin-left: 1.3rem;
      margin-right: 0.5rem;
    }
  }
  .content {
    padding-bottom: 0px !important;
  }
  .info-actions {
    padding: 0.75rem 1rem !important;
  }
  .info-action {
    color: black !important;
    min-width: 0 !important;
  }
  .title {
    font-size: 1.25rem;
    padding: 0.325rem 0rem;
  }
}

.comment-switch {
  background-color: rgba(0, 155, 222, 0.2);
  border-radius: 0.5rem;
  margin-top: 0.75rem;
  margin-right: 1.25rem;
  padding: 0.3rem;
  position: absolute;
  right: 0;
  z-index: 10;
  .vmoso_fonts{
    &:hover {
      color: #009bDE;
    }
  }
}

.multiple-line-snackbar {
  > div {
    height: auto !important;
    line-height: initial !important;
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
}

.read-only-url {
  input {
    color: #27A9E1 !important;
    cursor: pointer !important;
    @include ellipsis()
  }
}

.menu-divider {
  @include custom-divider;
  & {
    border-bottom-color: rgb(144, 164, 174);
    height: 0.125rem;
    margin: 0;
  }
}

.truncate {
  @include ellipsis();
}

.ned.audio {
  .editContainer {
    border-top: .0625rem solid #d1d1d1;
    height: 1px !important;
  }
  .mode-switcher {
    display: none;
  }
  .time {
    color: #c53e3e;
    margin: 0 0.5rem;
  }
}

.ned.micro {
  .editContainer {
    border-top: .0625rem solid #d1d1d1;
    height: 1px !important;
  }
  .mode-switcher {
    border: 1px solid #d1d1d1;
    border-radius: 1.25rem;
    color: #009bde;
    cursor: pointer;
    display: flex;
    padding: 0.25rem 1.5rem;
    white-space: nowrap;
    width: 100%;
  }
  > .tool-group {
    width: 100%;
  }
}

.ned.medium, .ned.mini, .ned.micro {
  .nedContent {
    width: 100% !important;
    .cke_chrome {
      width: 100% !important;
    }
  }
}

.nedContent {
  width: 100%;
}

/* Loading styles end */

/*** Mobile Common (All Phone Resolutions) ***/
@media only screen and (max-width: 768px) {
  #app {
    display: flex;
    flex-flow: row wrap;
    .homePage {
      flex-direction: column;
      width:100%;
      .streamContent {
        min-width: 100%;
      }
    }
    .login {
      .boxContainer {
        align-items: center;
        flex-direction: column-reverse;
      }
    }
    .sidebar > .content, .entityDetails, .streamContent {
      flex: 1 100%;
    }
    .sidebar {
      height: $sidebarHeight;
      max-width: 100%;
      position: inherit;
      order: 2;
      overflow: visible;
      width: 100%;
      z-index: 100;
      > .content {
        flex-direction: column;
      }
      .iconButtonDesktop {
        display: none;
      }
      &.expanded {
        height: 100vh;
        max-width: 100%;
        min-width: 100%;
        > .content {
          align-content: flex-start;
          flex-basis: 100%;
          flex-flow: row wrap;
          justify-content: space-between;
        }
        & + .streamContent {
          display: none;
        }
        .topButtons, .statusItems {
          align-items: flex-end;
          display: inherit;
        }
        .topButtons {
          order: 1;
          width: 50%;
          /*> div {*/
          > div:first-child {
            > span > div > div > span {
              display: none !important;
            }
          }
          /*}*/
          > li > div {
            min-width: 0;
          }
        }
        .statusItems {
          align-items: center;
          flex-direction: row-reverse;
          order: 2;
          width: 40%;
        }
        .zoneSection {
          flex: 1 0 100%;
          order: 3;
        }
        section {
          height: auto;
          /*> div {
            height: auto;
          }*/
          &.zoneSection .sidebar-divider {
            display: block !important;
            flex: 0 0 100%;
            height: 0;
          }
          .gmItem + div {
            display: none;
          }
        }
        .mobile-menu-button {
          height: 2.125rem;
          top: 3vh;
          .circle {
            background: transparent;
          }
          > span {
            color: rgb(255, 255, 255) !important;
          }
        }
        .sidebarCreateButton {
          display: none;
        }
      }
      .topButtons, .statusItems {
        display: none;
      }
      .sidebarCreateButton {
        bottom: 4.5rem;
        height: 4rem;
        position: absolute;
        right: .5rem;
        width: 4rem;
        z-index: 1000;
        .gmItem {
          > :not(:first-child) {
            display: none;
          }
        }
        .MuiFab-root {
          background-color: #009bde !important;
          border-radius: 50%;
          height: 3rem !important;
          width: 3rem !important;
          span.vmoso_fonts {
            color:white !important;
          }
        }
      }

      section {
        height: 100%;
        /*> div {
          height: 100%;
        }*/
        &.topButtons .gmItem > div > div {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        &.statusItems {
          @include gmSection;
          margin: 0 0.75rem;
          .gmItem {
            @include gmItem;
            flex: 0;
            > * {
              height: 2rem;
              width: 2rem;
              min-width: 0;
            }
            > *:not(:first-child) {
              display: none;
            }
            > div > span {
              left: 0;
            }
          }
          > div {
            flex-basis: 33% !important;
          }
        }
        &.zoneSection {
          @include gmSection;
          > div {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
          }
          .gmItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.8125rem 3.5rem !important;
            .badge {
              left: 0 !important;
            }
            > div:not(.badge) {
              @include ellipsis();
              min-width: 0;
            }
          }
        }
      }
    }
    .streamContent {
      order: 1;
      height: calc(100vh - #{$sidebarHeight});
      position: inherit;
      margin-left: inherit;
      max-width: 100%;
      .mega {
        height: calc(100vh - (#{$sidebarHeight})) !important;
        &.megaheader {
          height: calc(100vh - (#{$sidebarHeight}) - (#{$headerHeight})) !important;
          &.mega-hidden-stream {
            height: 0px !important;
          }
        }
      }
      .stream {
        height: calc(100vh - (#{$sidebarHeight} + 3.5rem)) !important;
      }
    }

    .homePage {
      .entityDetails {
        height: 100%;
      }
    }
    .paper {
      width: 100% !important;
      height: 100%;
    }
    .sidebar-divider {
      visibility: visible;
    }
    .mobile-menu-button {
      display: inherit !important;
      left: calc(50% - .75rem);
      top: calc(100vh - (#{$sidebarHeight} + 2.125rem));
      z-index: 1000;
      .circle {
        display: inline-block;
        margin: 0 1rem 1rem 0;
        background: rgba(67, 87, 97, 0.1);
        left: -1.75rem;
        position: absolute;
        height: 2.5rem;
        width: 5rem;
        border-top-left-radius: 5rem;
        border-top-right-radius: 5rem;
      }
    }
    .mobile-back-button {
      display: inherit !important;
    }
  }
  .entityCreate > div > div {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;
  }
  .settings {
    .settingForm {
      padding: .625rem;
      button.help {
        flex-grow: 0 !important;
      }
    }
  }
  .entityCreate > div > div {
    min-width: auto !important;
  }

  /* Mobile Loading UI for Sidebar */
  .sidebar.loading {
    .row {
      display: none;
    }
    .row:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
  .megaContainer {
    position: absolute !important;
    top: 0 !important;
    width: 100% !important;
    z-Index: 10000 !important;
  }
}

/*** Mobile Breakpoint - Small Phone Resolutions ***/
@media only screen and (max-width: 480px) {
  .settings {
    .tabbar {
      button.item {
        font-size: .625rem !important;
        overflow:hidden;
      }
    }
    .settingForm {
      button.romanizedNameHelper {
        left: 7rem;
        top: -2.125rem;
      }
      button.nameHelper {
        left: 9.875rem;
        top: -2.125rem;
      }
    }
  }
  .megaContainer {
    position: absolute !important;
    top: 0 !important;
    width: 100% !important;
    z-Index: 10000 !important;
  }
}

/*** Mobile Breakpoint - Average Phone Resolutions ***/
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .sidebar {
    .zoneSection {
      > div > li {
        height: $sidebarHeight;
      }
      .gmItem {
        .badge {
          width: 1.75rem !important;
          .material-icons {
            font-size: 1.75rem !important;
            height: 1.75rem !important;
            width: 1.75rem !important;
          }
        }
        > div {
          margin: 0 !important;
        }
      }
    }
  }
  .settings {
    .tabbar {
      button.item {
        font-size: .75rem !important;
      }
    }
    .settingForm {
      button.romanizedNameHelper {
        left: 122px;
        top: -2.125rem;
      }
      button.nameHelper {
        left: 166px;
        top: -2.125rem;
      }
    }
  }
  .chooser {
    .list {
      height: 25rem;
    }
  }
}

/*** Web Breakpoint - Tablet Resolutions ***/
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .homePage {
    .streamContent {
      min-width:12.5rem;
    }
    .sidebar {
      &.expanded, &.docked {
        min-width: 15.625rem;
        max-width: 15.625rem;
      }
    }
  }
  .Personal {
    button.info,
    button.cancel,
    button.add {
      position: absolute !important;
    }
    button.info,
    button.cancel {
      right: 14.375rem;
    }
    button.add {
      position: absolute !important;
      right: 17.5rem;
    }
    button.info, button.add {
      top: 11.375rem;
    }
    button.remove_email {
      top: 20.625rem;
    }
    button.cancel_email {
      top: 16.0625rem;
    }
    button.google_email {
      top: 38.625rem;
    }
  }
  .settingForm {
    button.help {
      position: absolute !important;
    }
    button.romanizedNameHelper {
      left: 20.9375rem;
      top: 13.4375rem;
    }
    button.nameHelper {
      left: 23.4375rem;
      top: 24.125rem;
    }
    .chooser {
      .list {
        height: 35rem;
      }
    }
  }

  .Provisioning {
    .personal {
      position: absolute;
      left: 19.6875rem;
      top: 15.375rem;
    }
    .enterprise {
      position: absolute;
      left: 27.8125rem;
      top: 15.375rem;
    }
  }
}

/*** Web Breakpoint - Desktop Resolutions ***/
@media only screen and (min-width: 1025px) and (max-width: 1920px) {
  .chooser {
    .list {
      height: 35rem;
    }
  }
}

/*** Web Breakpoint - Big Desktop Resolutions (1080p and up) ***/
@media only screen and (min-width: 1921px) and (min-height: 900px) {
  .homePage {
    .sidebar {
      &.expanded, &.docked {
        min-width: 33.125rem;
        max-width: 33.125rem;
      }
    }
  }
  .chooser {
    .list {
      height: 35rem;
    }
  }
}

.material-icons.chat {
  color: $chat-color !important;
}

.material-icons.post {
  color: $post-color !important;
}

.material-icons.content, .material-icons.note, .material-icons.bookmark, .material-icons.file {
  color: $content-color !important;
}

.material-icons.user {
  color: $user-color !important;
}

.pager {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
  li {
    display: inline;
    button {
      vertical-align: text-bottom;
      cursor: pointer;
    }
    button.disabled {
      cursor: default !important;
      .material-icons {
        color: #CCC !important;
      }
    }
    .pageOf {
      cursor: default;
      display: inline-block;
      padding: 0 2rem;
      vertical-align: text-bottom;
      p {
        span {
          padding: 0 .625rem;
        }
      }
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.login {
  background-color: #00314d;
  background-image: url('../images/login/Login-Background.png');
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  font-family: Roboto, Arial;
  font-size: .875rem;
  height: 100vh;
  overflow: auto;
  width: 100vw;
  .boxContainer {
    color: #546e7a;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3.125rem;
    text-align: center;
    .box {
      background-color: white;
      // background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      flex-direction: column;
      // justify-content: space-around;
      margin: .625rem;
      max-width: 24rem;
      min-width: 18.75rem;
      opacity: 0.8;
      overflow: hidden;
      padding: .3125rem;
      width: 35%;
      > div {
        padding: .625rem;
      }
      .buttons {
        margin: 1rem 0;
      }
      .cellHalf {
        padding: .625rem;
        width: 50%;
      }
      .checkbox {
        text-align: left;
      }
      .description {
        font-size: 1.125rem;
      }
      @include loginField();
      .heading {
        color: $user-color;
        font-family: Roboto, Arial;
        font-size: 1.25rem;
        font-weight: 500;
      }
      .link {
        color: $user-color;
        font-family: Roboto, Arial;
        text-decoration: none;
      }
      .logo {
        width: 14.8125rem;
      }
      .logoAppStore {
        height: 3.5rem;
      }
      .logoPlayStore {
        height: 3.5rem;
      }
      .notice {
        font-size: 1.0625rem;
        padding: 1.25rem;
      }
      .subHeading {
        color: $user-color;
        font-size: 1.25rem;
      }
    }
    @include loginRow;
  }
  // button {
  //   color: white;
  // }
  .loginFooter {
    align-items: center;
    background-color: #455b64;
    bottom: 0;
    display: flex;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: left;
    .footerItem {
      color: white;
      padding: 1rem;
      text-decoration: none;
      &.languageSelector > div > div {
        align-items: center;
        display: flex;
        flex-direction: row;
      }
      .expandButton {
        padding: 0;
        padding-left: .25rem;
      }
      .MuiSelect-select:focus {
        background-color: transparent;
      }
    }
  }
  .loginForm {
    flex-direction: column;
    padding: 0.625rem 2rem;
    .error {
      font-size: .75rem;
      margin-left: .625rem;
      text-align: left;
    }
    .field > div {
      text-align: left;
    }
  }
}


.authenticatorCode {
  align-items: 'space-evenly';
  flex-direction: 'row';

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: 'none';
    margin: 0;
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, .login .boxContainer .row {
    align-items: center;
  }
}

.suggestions-container {
  flex-grow: 1;
  position: relative;
}

.suggestions-container-open {
  left: 0;
  margin-top: 0;
  margin-bottom: 1.875rem;
  max-height: calc(100vh - 3.75rem);
  overflow: auto;
  position: absolute;
  right: 0;
  z-index: 10000;
  ul > li > div > span {
    > div > div > div:not(.autocompleteUserAvatar) {
      @include ellipsis();
      text-align: left;
    }
  }
}
.entityDetails .suggestions-list .suggestion {
  border: $tableBorderStyle;
  border-bottom: none;
}
.suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  .suggestion {
    display: block;
    a {
      color: inherit;
      text-decoration: none;
    }
    .menuItemLabel {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      > span {
        line-height: normal !important;
        @include ellipsis();
      }
      > div {
        @include ellipsis();
      }
    }
    .autocompleteUserAvatar {
      height: 2rem !important;
      width: 2rem !important;
      margin: 0 .5rem !important;
      left: .75rem !important;
      position: relative;
      display: inline-flex !important;
      align-items: center;
      .selectableAvatar {
        border: none;
        display: inherit !important;
        height: 2rem !important;
        width: 2rem !important;
        > div {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .inline-icon {
      align-items: center;
      display: flex;
      height: 3rem;
      justify-content: flex-start;
      &.avatar {
        .autocompleteUserAvatar {
          left: 0 !important;
        }
        /* .menuItemLabel {
          margin-left: .7rem;
        } */
        .vmoso_fonts.selectable {
          height: 1.5rem !important;
          margin: .75rem .25rem .75rem 1rem !important;
          width: 1.5rem !important;
        }
      }
      &.selectable {
        margin-left: 0;
        ~ .roleMenuBox {
          margin-left: 0;
        }
      }
      &.icon {
        padding-left: .2rem;
        .menuItemLabel {
          margin-left: .5rem;
        }
      }
      > .vmoso_fonts {
        margin-right: .5rem;
      }
    }

    .reverse {
      .autocompleteUserAvatar {
        left: inherit !important;
        position: relative !important;
      }
    }
    .arrowIcon {
      position: absolute !important;
      right: 0;
      top: 0;
      margin: .75rem;
    }
    .roleMenuBox {
      margin-left: 3rem;
      &.expanded {
        display: block;
      }
      &.collapse {
        display: none;
      }
    }
  }
}

.menu-item {
  display: flex !important;
  position: relative !important;
  align-items: center;
  justify-content: initial;

  .menu-item-icon {
    flex-shrink: 0;
    display: inline-block;
  }

  .menu-item-text {
    display: block;
    flex: 1 1 auto;
    padding: 0 1rem;
  }
}

.menuItemLabel > div, .listColumText {
  @include ellipsis();
}
.listColumText {
  margin-left: .5rem
}

/* Entity Preview Styles */
.entityViewer {
  text-align: center;
  nav {
    height: 3.125rem;
  }
  audio, img {
    max-height: calc(100vh - 14rem);
    max-width: 100%;
  }
  video {
    max-height: calc(100vh - 14rem);
    max-width: 100%;
    object-fit: fill;
  }
  .hideDialog, .showDialog {
    margin-top: 1rem;
    position: absolute !important;
    right: 1rem;
  }
  .creatingPreview {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .linearProgress {
    width: 80% !important;
    margin: auto !important;
  }
}

.file-input {
  cursor: pointer;
  position: relative;
  margin-top: 1rem;
  text-align: center;
}

.file-output {
  align-items: flex-end;
  display: flex;
  height: 4.5rem;
  position: relative;
  &.with-preview {
    .status-info {
      bottom: 1.5rem;
    }
  }
  &.has-preview {
    height: auto;
    margin-top: 2.3rem;
    .remove {
      bottom: inherit;
    }
  }
  .formCol > input {
    @include ellipsis(calc(100% - 12rem) !important);
  }
  .status-info {
    position: absolute;
    right: 4.0625rem;
    bottom: 1.25rem;
  }

  .remove {
    position: absolute !important;
    right: 0;
    bottom: 1.25rem;
  }
}

.logged-in-user {
  font-size: 0.825rem;
}

.notificationButton {
  cursor: pointer;
  background-color: #0288D1;
  border-radius: 12px;
  color: white;
  line-height: 24px;
  position: relative;
  width: 160px;
  margin: -12px auto;
  text-align: center;
  top: 20px;
  z-index: 10;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.9;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.streamResults {
  font-size: 12px;
  color: #8c8c8c;
  line-height: 18px;
  padding: 0.2rem 1rem 0.2rem 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.streamOutsideNotif {
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  margin-bottom: 0.1rem;
  background-color: #e8e8e8;
  font-size: 12px;
  color: #0288D1;
  font-weight: 500;
  text-decoration: none solid rgb(2, 136, 209);
  box-shadow: 0px 2px 2px rgba(0,0,0,0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .closeButton {
    height: 1.125rem !important;
    padding: 0 !important;
    width: 1.125rem !important;
    .vmoso_fonts {
      color: #0288D1 !important;
      font-size: 1.125rem !important;
    }
  }
}

.spaceType {
  background-color: transparent !important;
  color: $megaColor !important;
  font-size: 0.85rem !important;
  height: inherit !important;
  opacity: $megaOpacity;
  padding-left: 0.5rem;
  width: inherit !important;
}

.spaceType2 {
  background-color: transparent !important;
  color: #90a4ae !important;
  font-size: 1rem !important;
}

.space-menu-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.mega-creation {
  // max-width: calc(#{$streamMax} + 3.75rem) !important;
  min-width: 25rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .mega {
    .mega-item {
      padding: .75rem .75rem;
      .title-container, .title, .vmoso_fonts, span {
        background-color: transparent !important;
        opacity: 1 !important;
        &:hover {
          opacity: 1 !important;
        }
        .vmoso_fonts {
          font-size: 1.5rem !important;
        }
      }
    }
    :hover {
      background-color: rgba(38, 50, 56, 0.1) !important;
      // opacity: 0.1 !important;
    }
  }
  .stream-toolbar {
    position: initial !important;
  }
}

.mega-overlay {
  .overlay-megatoolbar {
    align-items: center;
    button:first-child {
      padding-left: 0 !important;
    }
  }
  .streamContent {
    max-width: 100%;
  }
}

.mega-stream {
  .overlay-megatoolbar {
    button:first-child {
      padding-left: 0 !important;
    }
  }
}

.err {
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  height: 100vh;
  justify-content: center;
}

.required {
  color: #C53E3E;
  padding: 0 0.25rem 0 0;
}

.popover-root {
  // This positions the popover way offscreen, which is then overridden (fixes flashing)
  // left: -31415px;
}

.sketch-picker {
  border-radius: 0 !important;
}

.ril-zoom-in {
  display: none;
}

.ril-zoom-out {
  display: none;
}

.lightbox-icon {
  cursor: pointer;
  margin: 0 0.5rem 0 0.5rem;
  vertical-align: middle;
}

.lightbox-pdf {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto auto;
}

.lightbox-pdf-pageOf {
  cursor: default;
  display: inline-block;
  padding: 0 .75rem;
  vertical-align: text-top;
  // margin-top: -2.2rem;
  p {
    display: inline-block;
    span {
      padding: 0 .1rem;
    }
  }
}

.lightbox-pdf-separator {
  background-color: white;
  display: inline-block;
  height: 1.2rem;
  margin: auto .75rem;
  width: 0.06rem;
}

.lightbox-pdf-iconButton {
  width: 30px !important;
}

.lightbox-pdf-select {
  display: inline-flex !important;
  margin-left: .75rem;
  width: 9.125rem !important;
  .MuiSelect-select {
    min-width: inherit;
  }
}

.lightbox-pdf-icon-disabled {
  color: rgba(225, 225, 225, 0.7) !important;
}

// .viewerContent {
//   background-color: lightgray;
// }

.suggestions-input input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.react-pdf__Page {
  // https://stackoverflow.com/a/33455342
  margin: auto;
  margin-bottom: .25rem;
  mark {
    opacity: 0.5;
    background: #ff0;
    box-shadow: 0px 0px 10px 5px #ff0;
    color: transparent;
    white-space: pre;
  }
}

.react-pdf__Page__textContent {
  color: transparent;
}

.preview-button {
  margin-left: 10px !important;
  margin-right: 10px !important;
  button {
    color: white;
  }
}

.preview-container {
  height: 100%;
  .searchBar {
    margin-right: .5rem;
    width: inherit;
    .MuiTextField-root {
      min-width: inherit;
    }
  }
  .searchMatches {
    display: inline;
    white-space: nowrap;
  }
}

.rteContent {
  img {
    max-width: 400px;
    max-height: 400px;
  }
  video {
    // max-width: 400px;
    // object-fit: fill;
    height: 360px;
  }
}

.hide {
  display: none;
}

/*** react-split-pane ***/
.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
.Pane2 {
  overflow: auto;
}

.firebaseui-idp-button[data-provider-id="linkedin.com"] .firebaseui-idp-text,
.firebaseui-idp-button[data-provider-id="vmoso.com"] .firebaseui-idp-text {
  color: #757575;
}
