$comment-action-height: 30px;

.ned.min {
  border-top: 0.0625rem solid #d1d1d1;

  .nedContent {
    .rte {
      span.cke_top {
        display: none;
      }
      .cke_contents {
        border: none;
      }
    }
  }
}

.ned.topSectionShow {
  .rte {
    .cke {
      border: none !important;
      span.cke_top {
        display: block;
        border: 1px solid #d1d1d1;
      }
    }
  }
  .controls {
    border: 1px solid #d1d1d1;
  }
  .hideSection,
  .showSection {
    display: flex;
    justify-content: flex-end;
  }
}
.ned.topSectionHide {
  .rte {
    .cke {
      border: none !important;
      span.cke_top {
        display: none;
      }
      .cke_contents {
        border-bottom: 1px solid #d1d1d1;
      }
    }
  }
  &.focusNed {
    .cke_contents {
      border-bottom: 2px solid rgb(0, 155, 222) !important;
    }
  }
  .hideSection,
  .showSection {
    display: flex;
    justify-content: flex-end;
  }
}
.ned.labelShow {
  .title {
    color: #78909c;
    font-size: 16px;
    transform: scale(0.75);
    transform-origin: left;
    .required {
      color: #c53e3e;
      padding: 0 0.25rem 0 0;
    }
    // &.readonly {
    //   padding-bottom: 0.5rem;
    // }
  }
  &.focusNed {
    .title {
    }
  }
}
.ned.labelHide {
  .title {
    display: none;
  }
}
.ned.medium {
  .nedContent {
    .rteActionInfo {
      .commentAction {
        z-index: 0;
      }
      .noteAction {
        z-index: 0;
      }
    }
    .rte {
      span.cke_top {
        display: block !important;
        height: auto !important;
        min-height: 48px !important;
        &.multi-line {
          height: auto !important;
        }
        &.single-line {
          height: 48px !important;
          &.with-commentAction {
            height: calc(48px + #{$comment-action-height}) !important;
          }
        }
        &.with-commentAction {
          padding-bottom: $comment-action-height !important;
        }
      }

      span.cke_toolbox {
        float: right;
        padding: 0.625rem;
      }
    }
  }
}
.ned.max {
  position: fixed;
  z-index: 200;
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .nedContent {
    // max-width: 1366px !important;
    // max-height: 768px;
    width: 100%;
    top: 0;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0px auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px;

    .dragHeader {
      background-color: #6c7378;
      cursor: move;
      display: flex;
      justify-content: flex-end;
      height: 30px;
      width: 100%;
      padding-right: 10px;
      padding-top: 7px;
    }
    .rteActionInfo {
      z-index: 1500;
      left: 0;
      right: 0;
      margin: 0 auto;
      .commentAction {
      }
      .noteAction {
      }
    }
    .rte {
      span.cke_top {
        display: block !important;
      }

      span.cke_toolbox {
        float: right;
        padding: 0.625rem;
      }
    }
    .maxModeResize {
      cursor: se-resize;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
.entityComments {
  .ned {
    .rte {
      width: 100%;
    }
  }
}
.ned {
  z-index: 100;

  .hideSection,
  .showSection {
    display: none;
  }
  .hideToolbar,
  .showToolbar {
    font-size: 12px !important;
    color: rgba(0, 143, 251, 0.87) !important;
    // position: absolute !important;
    // right: 3.2rem;
    margin-top: -1rem;
    z-index: 999;
  }
  .nedContent {
    position: relative;
    width: 100%;
    .rteActionInfo {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: white;
      position: relative;
      .actionInfo {
        font-style: italic;
      }
      .clearButton {
        padding: 0 !important;
        height: initial !important;
        width: initial !important;
        .vmoso_fonts {
          color: white !important;
          cursor: pointer;
          font-size: 1.5rem;
          margin: auto 5px;
        }
      }
      .commentAction {
        background-color: #009dbe;
        display: flex;
        align-items: center;
        width: 100%;
        height: $comment-action-height;
      }
      .noteAction {
        background-color: #ffb819;
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;
      }
    }
    .toggleReadonly {
      position: absolute !important;
      right: 0;
      top: -1rem;
    }

    .rte {
      span.cke_top {
        display: none;
        height: 48px !important;
        padding: 0 6px !important;
        box-sizing: border-box;
        &.force-wrap {
          height: auto !important;
          min-height: 48px !important;
        }
      }
      .cke_button__justifyblock {
        display: none;
      }
      .cke_combo_on {
        border: 0.0625rem #bcbcbc solid;
        margin-left: 0;
      }
      .cke_combo_disabled {
        opacity: 0.3;
      }
      .cke_combo__font {
        width: 2.25rem;
        height: 1.75rem;
        box-sizing: border-box;
        margin-bottom: 0;
        .cke_combo_button {
          display: inline-block;
          height: 1.125rem;
          padding: 0.25rem 0.375rem;
          margin-left: 0 !important;
          outline: 0;
          cursor: default;
          float: left;
          border: 0;
          position: relative;
          &:hover,
          &:active,
          &:focus {
            padding: 0.25rem 0.375rem;
            margin-left: 0 !important;
          }
        }
        .cke_combo_text {
          background-image: url(../images/ned/font.png);
          background-repeat: no-repeat;
          width: 1rem;
          height: 1rem;
          padding-left: 0;
          font-size: 0;
          line-height: 0;
          overflow: hidden;
        }
        .cke_combo_open {
          margin: 0;
        }
        .cke_combo_arrow {
          margin: 0.5rem 0 0 0.0625rem;
        }
        &:after {
          border: none !important;
        }
        &:hover,
        &:active,
        &:focus {
          @extend .cke_combo_on;
        }
      }
      .cke_combo__fontsize {
        @extend .cke_combo__font;
        .cke_combo_text {
          background-image: url(../images/ned/font-size.png) !important;
        }
      }
      .cke_button__source_label {
        display: none;
      }
      .cke_bottom {
        display: none;
      }
    }
  }

  .controls {
    border: 0;
    background-color: #ffffff !important;
    position: relative;
    padding: 0rem !important;
    cursor: auto;

    .min {
      margin-top: 1rem;
    }

    .medium,
    .min,
    .max {
      width: 1.5rem;
      height: 1.5rem;
    }

    .done {
      position: absolute !important;
      right: 1.125rem;
      top: 0.2rem;
      margin: 0 !important;
    }

    .vmoso_fonts {
      cursor: pointer;
    }
  }
  .postContent {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
}

.assignment {
  .nedContent {
    .toggleReadonly {
      top: -3rem;
    }
  }
}

.posting {
  .nedContent {
    .toggleReadonly {
      top: -2.5rem;
    }
  }
}

.aha {
  cursor: default;
  display: none;
  position: absolute;
  width: 290px;
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 4500;

  .ahaList {
    list-style-position: outside;
    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
    max-height: 280px;
    position: relative;

    .ahaItem {
      padding: 0 1rem;
      cursor: pointer;
      display: flex;
      font-size: 13px;
      height: 45px;
      .auxLine {
        color: grey;
        display: block;
        font-size: 0.75rem;
        font-weight: normal;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .name {
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 80%;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
      }
      &.selected {
        background-color: #e6f4fc;
      }
    }
  }
}

.popover {
  cursor: default;
  display: none;
  position: absolute;
  width: 190px;
  background-color: #fff;
  border: 1px solid #ddd;
  z-index: 4500;
  .popoverMenu {
    width: 188px !important;
    padding: 0;
    margin: 0;
    max-height: 280px;
    overflow: auto;
    position: relative;
  }
}

.emoji {
  width: 280px;
  .header {
    text-align: center;
    font-size: 15px;
    padding: 5px;
  }
}

.audioRecorder,
.videoRecorder,
.photoRecorder {
  .header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .title {
    height: 16px;
    font-size: 12px;
    margin-bottom: 15px;
    .time {
      display: inline-block;
      float: right;
    }
  }
  .controls {
    .progressbar {
      width: 220px;
      height: 24px;
      display: inline-block;
      margin: 0 10px;
      .line {
        position: relative;
        top: 11px;
        width: 100%;
        height: 6px;
        background-color: #d9d9d9;
        border-radius: 8px;
        .left {
          width: 0%;
          background-color: rgb(119, 180, 224);
          height: 100%;
          border-radius: 8px;
        }
        .pos {
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          background-color: rgb(119, 180, 224);
          top: -3px;
          left: 0;
        }
      }
    }
    .volume {
      display: inline-block;
      position: relative;
      .volumeBar {
        border-radius: 5px;
        width: 5px;
        height: 65px;
        top: -70px;
        background: #77b4e0;
        position: absolute;
        left: 8px;
        z-index: 200;
        .progress {
          width: 100%;
          height: 0;
          background: #d9d9d9;
          position: absolute;
          top: 0;
          border-radius: 5px;
        }
      }
    }
  }
  .mainButton {
    margin: 25px 0;
    align-content: center;
    display: flex;
    justify-content: center;
  }
  .actions {
    float: right;
    font-weight: bold;
  }
  .message {
    display: flex;
    color: red;
    width: 100%;
  }
}

.videoRecorder,
.photoRecorder {
  .content {
    height: 220px;
    width: 300px;
    position: relative;
    background-color: black;
    background-image: url("../images/login/Login-Background.png");
    video,
    img {
      width: 300px;
      height: 220px;
    }
    video {
      object-fit: fill;
    }
    .title {
      color: white;
      position: absolute;
      top: 8px;
      right: 10px;
      left: 10px;
    }
    .controls {
      position: absolute;
      bottom: 8px;
      width: 100%;
    }
  }
}

.entityBodySettings > .entitySettings {
  height: auto;
  .settingForm {
    padding: 1rem;
  }
  .form,
  settingForm {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
  }
}

.entityViewer > .entitySettings {
  height: auto;
  .settingForm {
    padding: 1rem;
  }
  .form,
  .settingForm {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
  }
}

.assignment_tab > .entitySettings {
  height: 100%;
}

.post {
  height: 100%;
  .formRow.document,
  .ned {
    height: 100%;
  }
  .ned > div:first-of-type {
    height: 100%;
    div:first-of-type {
      height: 100%;
      .rte {
        height: 100%;
        div:first-of-type {
          height: 100%;
        }
        .cke_inner {
          height: 100%;
        }
      }
    }
  }

  .formRow.document {
    flex-grow: 1;
    margin: unset;
  }
}

.inlineComment > div {
  overflow-y: visible !important;
}

.react-draggable {
  z-index: 1;
}
