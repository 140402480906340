@font-face {
  font-family: 'vmoso_fonts';
  src:  url('../fonts/custom/vmoso_fonts.eot?atslvj');
  src:  url('../fonts/custom/vmoso_fonts.eot?atslvj#iefix') format('embedded-opentype'),
    url('../fonts/custom/vmoso_fonts.woff2?atslvj') format('woff2'),
    url('../fonts/custom/vmoso_fonts.ttf?atslvj') format('truetype'),
    url('../fonts/custom/vmoso_fonts.woff?atslvj') format('woff'),
    url('../fonts/custom/vmoso_fonts.svg?atslvj#vmoso_fonts') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon_"], [class*=" icon_"], .vmoso_fonts {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'vmoso_fonts' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_v_drawer_pinned:before {
  content: "\e01f";
}
.icon_v_photo:before {
  content: "\e410";
}
.icon_v_group:before {
  content: "\e8d3";
}
.icon_v_permissions:before {
  content: "\e900";
}
.icon_v_post_wall:before {
  content: "\e901";
}
.icon_ac_unit:before {
  content: "\e902";
}
.icon_access_alarm:before {
  content: "\e903";
}
.icon_access_alarms:before {
  content: "\e904";
}
.icon_access_time:before {
  content: "\e905";
}
.icon_accessibility:before {
  content: "\e906";
}
.icon_v_mark_favorite:before {
  content: "\e907";
}
.icon_accessible:before {
  content: "\e908";
}
.icon_account_balance:before {
  content: "\e909";
}
.icon_account_balance_wallet:before {
  content: "\e90a";
}
.icon_account_box:before {
  content: "\e90b";
}
.icon_account_circle:before {
  content: "\e90c";
}
.icon_adb:before {
  content: "\e90d";
}
.icon_add:before {
  content: "\e90e";
}
.icon_add_a_photo:before {
  content: "\e90f";
}
.icon_v_mark_follow:before {
  content: "\e910";
}
.icon_add_alarm:before {
  content: "\e911";
}
.icon_add_box:before {
  content: "\e912";
}
.icon_add_circle:before {
  content: "\e913";
}
.icon_add_circle_outline:before {
  content: "\e914";
}
.icon_add_location:before {
  content: "\e915";
}
.icon_add_shopping_cart:before {
  content: "\e916";
}
.icon_add_to_photos:before {
  content: "\e917";
}
.icon_add_to_queue:before {
  content: "\e918";
}
.icon_adjust:before {
  content: "\e919";
}
.icon_airline_seat_flat:before {
  content: "\e91a";
}
.icon_airline_seat_flat_angled:before {
  content: "\e91b";
}
.icon_airline_seat_individual_suite:before {
  content: "\e91c";
}
.icon_airline_seat_legroom_extra:before {
  content: "\e91d";
}
.icon_airline_seat_legroom_normal:before {
  content: "\e91e";
}
.icon_airline_seat_legroom_reduced:before {
  content: "\e91f";
}
.icon_airline_seat_recline_extra:before {
  content: "\e920";
}
.icon_airline_seat_recline_normal:before {
  content: "\e921";
}
.icon_airplanemode_active:before {
  content: "\e922";
}
.icon_airplanemode_inactive:before {
  content: "\e923";
}
.icon_airplay:before {
  content: "\e924";
}
.icon_airport_shuttle:before {
  content: "\e925";
}
.icon_alarm:before {
  content: "\e926";
}
.icon_alarm_add:before {
  content: "\e927";
}
.icon_alarm_off:before {
  content: "\e928";
}
.icon_alarm_on:before {
  content: "\e929";
}
.icon_album:before {
  content: "\e92a";
}
.icon_all_inclusive:before {
  content: "\e92b";
}
.icon_v_mark_helpful_no:before {
  content: "\e92c";
}
.icon_v_mark_helpful_yes:before {
  content: "\e92d";
}
.icon_v_mark_hide:before {
  content: "\e92e";
}
.icon_v_mark_important:before {
  content: "\e92f";
}
.icon_v_mark_menu:before {
  content: "\e930";
}
.icon_v_mark_pin:before {
  content: "\e931";
}
.icon_v_aha:before {
  content: "\e932";
}
.icon_v_all:before {
  content: "\e933";
}
.icon_v_file_outline:before {
  content: "\e934";
}
.icon_v_follow:before {
  content: "\e935";
}
.icon_v_form_spec:before {
  content: "\e936";
}
.icon_v_format_highlight:before {
  content: "\e937";
}
.icon_v_html:before {
  content: "\e938";
}
.icon_v_ios_back:before {
  content: "\e939";
}
.icon_v_link:before {
  content: "\e93a";
}
.icon_v_unlink:before {
  content: "\e93b";
}
.icon_v_mark_all_read:before {
  content: "\e93c";
}
.icon_v_mark_unread:before {
  content: "\e93d";
}
.icon_v_size_max_med:before {
  content: "\e93e";
}
.icon_v_size_max:before {
  content: "\e93f";
}
.icon_v_size_medium:before {
  content: "\e940";
}
.icon_v_size_mini:before {
  content: "\e941";
}
.icon_v_beginning:before {
  content: "\e942";
}
.icon_v_lifecycle:before {
  content: "\e943";
}
.icon_v_next:before {
  content: "\e944";
}
.icon_v_end:before {
  content: "\e945";
}
.icon_v_popular_topics:before {
  content: "\e946";
}
.icon_v_post:before {
  content: "\e947";
}
.icon_v_priority:before {
  content: "\e948";
}
.icon_v_space:before {
  content: "\e949";
}
.icon_v_sort_A_Z:before {
  content: "\e94a";
}
.icon_v_sort_Z_A:before {
  content: "\e94b";
}
.icon_v_table:before {
  content: "\e94c";
}
.icon_v_org_group:before {
  content: "\e94d";
}
.icon_v_threaded:before {
  content: "\e94e";
}
.icon_v_workflow_spec:before {
  content: "\e94f";
}
.icon_v_translate_spec:before {
  content: "\e950";
}
.icon_v_onboarding:before {
  content: "\e951";
}
.icon_v_vcard:before {
  content: "\e952";
}
.icon_v_typing_1:before {
  content: "\e953";
}
.icon_v_typing_2:before {
  content: "\e954";
}
.icon_v_typing_3:before {
  content: "\e955";
}
.icon_v_typing_4:before {
  content: "\e956";
}
.icon_all_out:before {
  content: "\e957";
}
.icon_v_checkbox_blank_circle_outline:before {
  content: "\e958";
}
.icon_v_checkbox_marked_circle_outline:before {
  content: "\e959";
}
.icon_v_pin:before {
  content: "\e95a";
}
.icon_v_drawer_unpinned:before {
  content: "\e95b";
}
.icon_v_note_outline:before {
  content: "\e95c";
}
.icon_v_logout:before {
  content: "\e95d";
}
.icon_v_comments:before {
  content: "\e95e";
}
.icon_v_continue:before {
  content: "\e95f";
}
.icon_android:before {
  content: "\e960";
}
.icon_v_zip_box:before {
  content: "\e961";
}
.icon_v_file_powerpoint_box:before {
  content: "\e962";
}
.icon_v_file_excel_box:before {
  content: "\e963";
}
.icon_v_file_word_box:before {
  content: "\e964";
}
.icon_v_file_pdf_box:before {
  content: "\e965";
}
.icon_v_file_delimited:before {
  content: "\e966";
}
.icon_announcement:before {
  content: "\e967";
}
.icon_apps:before {
  content: "\e968";
}
.icon_archive:before {
  content: "\e969";
}
.icon_arrow_back:before {
  content: "\e96a";
}
.icon_arrow_downward:before {
  content: "\e96b";
}
.icon_arrow_drop_down:before {
  content: "\e96c";
}
.icon_arrow_drop_down_circle:before {
  content: "\e96d";
}
.icon_arrow_drop_up:before {
  content: "\e96e";
}
.icon_arrow_forward:before {
  content: "\e96f";
}
.icon_arrow_upward:before {
  content: "\e970";
}
.icon_art_track:before {
  content: "\e971";
}
.icon_aspect_ratio:before {
  content: "\e972";
}
.icon_assessment:before {
  content: "\e973";
}
.icon_assignment:before {
  content: "\e974";
}
.icon_assignment_ind:before {
  content: "\e975";
}
.icon_assignment_late:before {
  content: "\e976";
}
.icon_assignment_return:before {
  content: "\e977";
}
.icon_assignment_returned:before {
  content: "\e978";
}
.icon_assignment_turned_in:before {
  content: "\e979";
}
.icon_assistant:before {
  content: "\e97a";
}
.icon_assistant_photo:before {
  content: "\e97b";
}
.icon_attach_file:before {
  content: "\e97c";
}
.icon_attach_money:before {
  content: "\e97d";
}
.icon_attachment:before {
  content: "\e97e";
}
.icon_audiotrack:before {
  content: "\e97f";
}
.icon_autorenew:before {
  content: "\e980";
}
.icon_av_timer:before {
  content: "\e981";
}
.icon_backspace:before {
  content: "\e982";
}
.icon_backup:before {
  content: "\e983";
}
.icon_battery_20:before {
  content: "\e984";
}
.icon_battery_30:before {
  content: "\e985";
}
.icon_battery_50:before {
  content: "\e986";
}
.icon_battery_60:before {
  content: "\e987";
}
.icon_battery_80:before {
  content: "\e988";
}
.icon_battery_90:before {
  content: "\e989";
}
.icon_battery_alert:before {
  content: "\e98a";
}
.icon_battery_charging_20:before {
  content: "\e98b";
}
.icon_battery_charging_30:before {
  content: "\e98c";
}
.icon_battery_charging_50:before {
  content: "\e98d";
}
.icon_battery_charging_60:before {
  content: "\e98e";
}
.icon_battery_charging_80:before {
  content: "\e98f";
}
.icon_battery_charging_90:before {
  content: "\e990";
}
.icon_battery_charging_full:before {
  content: "\e991";
}
.icon_battery_full:before {
  content: "\e992";
}
.icon_battery_std:before {
  content: "\e993";
}
.icon_battery_unknown:before {
  content: "\e994";
}
.icon_beach_access:before {
  content: "\e995";
}
.icon_beenhere:before {
  content: "\e996";
}
.icon_block:before {
  content: "\e997";
}
.icon_bluetooth:before {
  content: "\e998";
}
.icon_bluetooth_audio:before {
  content: "\e999";
}
.icon_bluetooth_connected:before {
  content: "\e99a";
}
.icon_bluetooth_disabled:before {
  content: "\e99b";
}
.icon_bluetooth_searching:before {
  content: "\e99c";
}
.icon_blur_circular:before {
  content: "\e99d";
}
.icon_blur_linear:before {
  content: "\e99e";
}
.icon_blur_off:before {
  content: "\e99f";
}
.icon_blur_on:before {
  content: "\e9a0";
}
.icon_book:before {
  content: "\e9a1";
}
.icon_bookmark:before {
  content: "\e9a2";
}
.icon_bookmark_border:before {
  content: "\e9a3";
}
.icon_border_all:before {
  content: "\e9a4";
}
.icon_border_bottom:before {
  content: "\e9a5";
}
.icon_border_clear:before {
  content: "\e9a6";
}
.icon_border_color:before {
  content: "\e9a7";
}
.icon_border_horizontal:before {
  content: "\e9a8";
}
.icon_border_inner:before {
  content: "\e9a9";
}
.icon_border_left:before {
  content: "\e9aa";
}
.icon_border_outer:before {
  content: "\e9ab";
}
.icon_border_right:before {
  content: "\e9ac";
}
.icon_border_style:before {
  content: "\e9ad";
}
.icon_border_top:before {
  content: "\e9ae";
}
.icon_border_vertical:before {
  content: "\e9af";
}
.icon_branding_watermark:before {
  content: "\e9b0";
}
.icon_brightness_1:before {
  content: "\e9b1";
}
.icon_brightness_2:before {
  content: "\e9b2";
}
.icon_brightness_3:before {
  content: "\e9b3";
}
.icon_brightness_4:before {
  content: "\e9b4";
}
.icon_brightness_5:before {
  content: "\e9b5";
}
.icon_brightness_6:before {
  content: "\e9b6";
}
.icon_brightness_7:before {
  content: "\e9b7";
}
.icon_brightness_auto:before {
  content: "\e9b8";
}
.icon_brightness_high:before {
  content: "\e9b9";
}
.icon_brightness_low:before {
  content: "\e9ba";
}
.icon_brightness_medium:before {
  content: "\e9bb";
}
.icon_broken_image:before {
  content: "\e9bc";
}
.icon_brush:before {
  content: "\e9bd";
}
.icon_bubble_chart:before {
  content: "\e9be";
}
.icon_bug_report:before {
  content: "\e9bf";
}
.icon_build:before {
  content: "\e9c0";
}
.icon_burst_mode:before {
  content: "\e9c1";
}
.icon_business:before {
  content: "\e9c2";
}
.icon_business_center:before {
  content: "\e9c3";
}
.icon_cached:before {
  content: "\e9c4";
}
.icon_cake:before {
  content: "\e9c5";
}
.icon_call:before {
  content: "\e9c6";
}
.icon_call_end:before {
  content: "\e9c7";
}
.icon_call_made:before {
  content: "\e9c8";
}
.icon_call_merge:before {
  content: "\e9c9";
}
.icon_call_missed:before {
  content: "\e9ca";
}
.icon_call_missed_outgoing:before {
  content: "\e9cb";
}
.icon_call_received:before {
  content: "\e9cc";
}
.icon_call_split:before {
  content: "\e9cd";
}
.icon_call_to_action:before {
  content: "\e9ce";
}
.icon_camera:before {
  content: "\e9cf";
}
.icon_camera_alt:before {
  content: "\e9d0";
}
.icon_camera_enhance:before {
  content: "\e9d1";
}
.icon_camera_front:before {
  content: "\e9d2";
}
.icon_camera_rear:before {
  content: "\e9d3";
}
.icon_camera_roll:before {
  content: "\e9d4";
}
.icon_cancel:before {
  content: "\e9d5";
}
.icon_card_giftcard:before {
  content: "\e9d6";
}
.icon_card_membership:before {
  content: "\e9d7";
}
.icon_card_travel:before {
  content: "\e9d8";
}
.icon_casino:before {
  content: "\e9d9";
}
.icon_cast:before {
  content: "\e9da";
}
.icon_cast_connected:before {
  content: "\e9db";
}
.icon_center_focus_strong:before {
  content: "\e9dc";
}
.icon_center_focus_weak:before {
  content: "\e9dd";
}
.icon_change_history:before {
  content: "\e9de";
}
.icon_chat:before {
  content: "\e9df";
}
.icon_chat_bubble:before {
  content: "\e9e0";
}
.icon_chat_bubble_outline:before {
  content: "\e9e1";
}
.icon_check:before {
  content: "\e9e2";
}
.icon_check_box:before {
  content: "\e9e3";
}
.icon_check_box_outline_blank:before {
  content: "\e9e4";
}
.icon_check_circle:before {
  content: "\e9e5";
}
.icon_chevron_left:before {
  content: "\e9e6";
}
.icon_chevron_right:before {
  content: "\e9e7";
}
.icon_child_care:before {
  content: "\e9e8";
}
.icon_child_friendly:before {
  content: "\e9e9";
}
.icon_chrome_reader_mode:before {
  content: "\e9ea";
}
.icon_class:before {
  content: "\e9eb";
}
.icon_clear:before {
  content: "\e9ec";
}
.icon_clear_all:before {
  content: "\e9ed";
}
.icon_close:before {
  content: "\e9ee";
}
.icon_closed_caption:before {
  content: "\e9ef";
}
.icon_cloud:before {
  content: "\e9f0";
}
.icon_cloud_circle:before {
  content: "\e9f1";
}
.icon_cloud_done:before {
  content: "\e9f2";
}
.icon_cloud_download:before {
  content: "\e9f3";
}
.icon_cloud_off:before {
  content: "\e9f4";
}
.icon_cloud_queue:before {
  content: "\e9f5";
}
.icon_cloud_upload:before {
  content: "\e9f6";
}
.icon_code:before {
  content: "\e9f7";
}
.icon_collections:before {
  content: "\e9f8";
}
.icon_collections_bookmark:before {
  content: "\e9f9";
}
.icon_color_lens:before {
  content: "\e9fa";
}
.icon_colorize:before {
  content: "\e9fb";
}
.icon_comment:before {
  content: "\e9fc";
}
.icon_compare:before {
  content: "\e9fd";
}
.icon_compare_arrows:before {
  content: "\e9fe";
}
.icon_computer:before {
  content: "\e9ff";
}
.icon_confirmation_number:before {
  content: "\ea00";
}
.icon_contact_mail:before {
  content: "\ea01";
}
.icon_contact_phone:before {
  content: "\ea02";
}
.icon_contacts:before {
  content: "\ea03";
}
.icon_content_copy:before {
  content: "\ea04";
}
.icon_content_cut:before {
  content: "\ea05";
}
.icon_content_paste:before {
  content: "\ea06";
}
.icon_control_point:before {
  content: "\ea07";
}
.icon_control_point_duplicate:before {
  content: "\ea08";
}
.icon_copyright:before {
  content: "\ea09";
}
.icon_create:before {
  content: "\ea0a";
}
.icon_create_new_folder:before {
  content: "\ea0b";
}
.icon_credit_card:before {
  content: "\ea0c";
}
.icon_crop:before {
  content: "\ea0d";
}
.icon_crop_16_9:before {
  content: "\ea0e";
}
.icon_crop_3_2:before {
  content: "\ea0f";
}
.icon_crop_5_4:before {
  content: "\ea10";
}
.icon_crop_7_5:before {
  content: "\ea11";
}
.icon_crop_din:before {
  content: "\ea12";
}
.icon_crop_free:before {
  content: "\ea13";
}
.icon_crop_landscape:before {
  content: "\ea14";
}
.icon_crop_original:before {
  content: "\ea15";
}
.icon_crop_portrait:before {
  content: "\ea16";
}
.icon_crop_rotate:before {
  content: "\ea17";
}
.icon_crop_square:before {
  content: "\ea18";
}
.icon_d_rotation:before {
  content: "\ea19";
}
.icon_dashboard:before {
  content: "\ea1a";
}
.icon_data_usage:before {
  content: "\ea1b";
}
.icon_date_range:before {
  content: "\ea1c";
}
.icon_dehaze:before {
  content: "\ea1d";
}
.icon_delete:before {
  content: "\ea1e";
}
.icon_delete_forever:before {
  content: "\ea1f";
}
.icon_delete_sweep:before {
  content: "\ea20";
}
.icon_description:before {
  content: "\ea21";
}
.icon_desktop_mac:before {
  content: "\ea22";
}
.icon_desktop_windows:before {
  content: "\ea23";
}
.icon_details:before {
  content: "\ea24";
}
.icon_developer_board:before {
  content: "\ea25";
}
.icon_developer_mode:before {
  content: "\ea26";
}
.icon_device_hub:before {
  content: "\ea27";
}
.icon_devices:before {
  content: "\ea28";
}
.icon_devices_other:before {
  content: "\ea29";
}
.icon_dialer_sip:before {
  content: "\ea2a";
}
.icon_dialpad:before {
  content: "\ea2b";
}
.icon_directions:before {
  content: "\ea2c";
}
.icon_directions_bike:before {
  content: "\ea2d";
}
.icon_directions_boat:before {
  content: "\ea2e";
}
.icon_directions_bus:before {
  content: "\ea2f";
}
.icon_directions_car:before {
  content: "\ea30";
}
.icon_directions_railway:before {
  content: "\ea31";
}
.icon_directions_run:before {
  content: "\ea32";
}
.icon_directions_subway:before {
  content: "\ea33";
}
.icon_directions_transit:before {
  content: "\ea34";
}
.icon_directions_walk:before {
  content: "\ea35";
}
.icon_disc_full:before {
  content: "\ea36";
}
.icon_dns:before {
  content: "\ea37";
}
.icon_do_not_disturb:before {
  content: "\ea38";
}
.icon_do_not_disturb_alt:before {
  content: "\ea39";
}
.icon_do_not_disturb_off:before {
  content: "\ea3a";
}
.icon_do_not_disturb_on:before {
  content: "\ea3b";
}
.icon_dock:before {
  content: "\ea3c";
}
.icon_domain:before {
  content: "\ea3d";
}
.icon_done:before {
  content: "\ea3e";
}
.icon_done_all:before {
  content: "\ea3f";
}
.icon_donut_large:before {
  content: "\ea40";
}
.icon_donut_small:before {
  content: "\ea41";
}
.icon_drafts:before {
  content: "\ea42";
}
.icon_drag_handle:before {
  content: "\ea43";
}
.icon_drive_eta:before {
  content: "\ea44";
}
.icon_dvr:before {
  content: "\ea45";
}
.icon_edit:before {
  content: "\ea46";
}
.icon_edit_location:before {
  content: "\ea47";
}
.icon_eject:before {
  content: "\ea48";
}
.icon_email:before {
  content: "\ea49";
}
.icon_enhanced_encryption:before {
  content: "\ea4a";
}
.icon_equalizer:before {
  content: "\ea4b";
}
.icon_euro_symbol:before {
  content: "\ea4c";
}
.icon_ev_station:before {
  content: "\ea4d";
}
.icon_event:before {
  content: "\ea4e";
}
.icon_event_available:before {
  content: "\ea4f";
}
.icon_event_busy:before {
  content: "\ea50";
}
.icon_event_note:before {
  content: "\ea51";
}
.icon_event_seat:before {
  content: "\ea52";
}
.icon_exit_to_app:before {
  content: "\ea53";
}
.icon_expand_less:before {
  content: "\ea54";
}
.icon_expand_more:before {
  content: "\ea55";
}
.icon_explicit:before {
  content: "\ea56";
}
.icon_explore:before {
  content: "\ea57";
}
.icon_exposure:before {
  content: "\ea58";
}
.icon_exposure_neg_1:before {
  content: "\ea59";
}
.icon_exposure_neg_2:before {
  content: "\ea5a";
}
.icon_exposure_plus_1:before {
  content: "\ea5b";
}
.icon_exposure_plus_2:before {
  content: "\ea5c";
}
.icon_exposure_zero:before {
  content: "\ea5d";
}
.icon_extension:before {
  content: "\ea5e";
}
.icon_face:before {
  content: "\ea5f";
}
.icon_fast_forward:before {
  content: "\ea60";
}
.icon_fast_rewind:before {
  content: "\ea61";
}
.icon_favorite:before {
  content: "\ea62";
}
.icon_favorite_border:before {
  content: "\ea63";
}
.icon_featured_play_list:before {
  content: "\ea64";
}
.icon_featured_video:before {
  content: "\ea65";
}
.icon_feedback:before {
  content: "\ea66";
}
.icon_fiber_dvr:before {
  content: "\ea67";
}
.icon_fiber_manual_record:before {
  content: "\ea68";
}
.icon_fiber_new:before {
  content: "\ea69";
}
.icon_fiber_pin:before {
  content: "\ea6a";
}
.icon_fiber_smart_record:before {
  content: "\ea6b";
}
.icon_file_download:before {
  content: "\ea6c";
}
.icon_file_upload:before {
  content: "\ea6d";
}
.icon_filter:before {
  content: "\ea6e";
}
.icon_filter_1:before {
  content: "\ea6f";
}
.icon_filter_2:before {
  content: "\ea70";
}
.icon_filter_3:before {
  content: "\ea71";
}
.icon_filter_4:before {
  content: "\ea72";
}
.icon_filter_5:before {
  content: "\ea73";
}
.icon_filter_6:before {
  content: "\ea74";
}
.icon_filter_7:before {
  content: "\ea75";
}
.icon_filter_8:before {
  content: "\ea76";
}
.icon_filter_9:before {
  content: "\ea77";
}
.icon_filter_9_plus:before {
  content: "\ea78";
}
.icon_filter_b_and_w:before {
  content: "\ea79";
}
.icon_filter_center_focus:before {
  content: "\ea7a";
}
.icon_filter_drama:before {
  content: "\ea7b";
}
.icon_filter_frames:before {
  content: "\ea7c";
}
.icon_filter_hdr:before {
  content: "\ea7d";
}
.icon_filter_list:before {
  content: "\ea7e";
}
.icon_filter_none:before {
  content: "\ea7f";
}
.icon_filter_tilt_shift:before {
  content: "\ea80";
}
.icon_filter_vintage:before {
  content: "\ea81";
}
.icon_find_in_page:before {
  content: "\ea82";
}
.icon_find_replace:before {
  content: "\ea83";
}
.icon_fingerprint:before {
  content: "\ea84";
}
.icon_first_page:before {
  content: "\ea85";
}
.icon_fitness_center:before {
  content: "\ea86";
}
.icon_flag:before {
  content: "\ea87";
}
.icon_flare:before {
  content: "\ea88";
}
.icon_flash_auto:before {
  content: "\ea89";
}
.icon_flash_off:before {
  content: "\ea8a";
}
.icon_flash_on:before {
  content: "\ea8b";
}
.icon_flight:before {
  content: "\ea8c";
}
.icon_flight_land:before {
  content: "\ea8d";
}
.icon_flight_takeoff:before {
  content: "\ea8e";
}
.icon_flip:before {
  content: "\ea8f";
}
.icon_flip_to_back:before {
  content: "\ea90";
}
.icon_flip_to_front:before {
  content: "\ea91";
}
.icon_folder:before {
  content: "\ea92";
}
.icon_folder_open:before {
  content: "\ea93";
}
.icon_folder_shared:before {
  content: "\ea94";
}
.icon_folder_special:before {
  content: "\ea95";
}
.icon_font_download:before {
  content: "\ea96";
}
.icon_format_align_center:before {
  content: "\ea97";
}
.icon_format_align_justify:before {
  content: "\ea98";
}
.icon_format_align_left:before {
  content: "\ea99";
}
.icon_format_align_right:before {
  content: "\ea9a";
}
.icon_format_bold:before {
  content: "\ea9b";
}
.icon_format_clear:before {
  content: "\ea9c";
}
.icon_format_color_fill:before {
  content: "\ea9d";
}
.icon_format_color_reset:before {
  content: "\ea9e";
}
.icon_format_color_text:before {
  content: "\ea9f";
}
.icon_format_indent_decrease:before {
  content: "\eaa0";
}
.icon_format_indent_increase:before {
  content: "\eaa1";
}
.icon_format_italic:before {
  content: "\eaa2";
}
.icon_format_line_spacing:before {
  content: "\eaa3";
}
.icon_format_list_bulleted:before {
  content: "\eaa4";
}
.icon_format_list_numbered:before {
  content: "\eaa5";
}
.icon_format_paint:before {
  content: "\eaa6";
}
.icon_format_quote:before {
  content: "\eaa7";
}
.icon_format_shapes:before {
  content: "\eaa8";
}
.icon_format_size:before {
  content: "\eaa9";
}
.icon_format_strikethrough:before {
  content: "\eaaa";
}
.icon_format_textdirection_l_to_r:before {
  content: "\eaab";
}
.icon_format_textdirection_r_to_l:before {
  content: "\eaac";
}
.icon_format_underlined:before {
  content: "\eaad";
}
.icon_forum:before {
  content: "\eaae";
}
.icon_forward:before {
  content: "\eaaf";
}
.icon_forward_10:before {
  content: "\eab0";
}
.icon_forward_30:before {
  content: "\eab1";
}
.icon_forward_5:before {
  content: "\eab2";
}
.icon_free_breakfast:before {
  content: "\eab3";
}
.icon_fullscreen:before {
  content: "\eab4";
}
.icon_fullscreen_exit:before {
  content: "\eab5";
}
.icon_functions:before {
  content: "\eab6";
}
.icon_g_translate:before {
  content: "\eab7";
}
.icon_gamepad:before {
  content: "\eab8";
}
.icon_games:before {
  content: "\eab9";
}
.icon_gavel:before {
  content: "\eaba";
}
.icon_gesture:before {
  content: "\eabb";
}
.icon_get_app:before {
  content: "\eabc";
}
.icon_gif:before {
  content: "\eabd";
}
.icon_golf_course:before {
  content: "\eabe";
}
.icon_gps_fixed:before {
  content: "\eabf";
}
.icon_gps_not_fixed:before {
  content: "\eac0";
}
.icon_gps_off:before {
  content: "\eac1";
}
.icon_grade:before {
  content: "\eac2";
}
.icon_gradient:before {
  content: "\eac3";
}
.icon_grain:before {
  content: "\eac4";
}
.icon_grapheq:before {
  content: "\eac5";
}
.icon_grid_off:before {
  content: "\eac6";
}
.icon_grid_on:before {
  content: "\eac7";
}
.icon_group:before {
  content: "\eac8";
}
.icon_group_add:before {
  content: "\eac9";
}
.icon_group_work:before {
  content: "\eaca";
}
.icon_hd:before {
  content: "\eacb";
}
.icon_hdr_off:before {
  content: "\eacc";
}
.icon_hdr_on:before {
  content: "\eacd";
}
.icon_hdr_strong:before {
  content: "\eace";
}
.icon_hdr_weak:before {
  content: "\eacf";
}
.icon_headset:before {
  content: "\ead0";
}
.icon_headset_mic:before {
  content: "\ead1";
}
.icon_healing:before {
  content: "\ead2";
}
.icon_hearing:before {
  content: "\ead3";
}
.icon_help:before {
  content: "\ead4";
}
.icon_help_outline:before {
  content: "\ead5";
}
.icon_high_quality:before {
  content: "\ead6";
}
.icon_highlight:before {
  content: "\ead7";
}
.icon_highlight_off:before {
  content: "\ead8";
}
.icon_history:before {
  content: "\ead9";
}
.icon_home:before {
  content: "\eada";
}
.icon_hot_tub:before {
  content: "\eadb";
}
.icon_hotel:before {
  content: "\eadc";
}
.icon_hourglass_empty:before {
  content: "\eadd";
}
.icon_hourglass_full:before {
  content: "\eade";
}
.icon_http:before {
  content: "\eadf";
}
.icon_https:before {
  content: "\eae0";
}
.icon_image2:before {
  content: "\eae1";
}
.icon_image_aspect_ratio:before {
  content: "\eae2";
}
.icon_import_contacts:before {
  content: "\eae3";
}
.icon_import_export:before {
  content: "\eae4";
}
.icon_important_devices:before {
  content: "\eae5";
}
.icon_inbox:before {
  content: "\eae6";
}
.icon_indeterminate_check_box:before {
  content: "\eae7";
}
.icon_info:before {
  content: "\eae8";
}
.icon_info_outline:before {
  content: "\eae9";
}
.icon_input:before {
  content: "\eaea";
}
.icon_insert_chart:before {
  content: "\eaeb";
}
.icon_insert_comment:before {
  content: "\eaec";
}
.icon_insert_drive_file:before {
  content: "\eaed";
}
.icon_insert_emoticon:before {
  content: "\eaee";
}
.icon_insert_invitation:before {
  content: "\eaef";
}
.icon_insert_link:before {
  content: "\eaf0";
}
.icon_insert_photo:before {
  content: "\eaf1";
}
.icon_invert_colors:before {
  content: "\eaf2";
}
.icon_invert_colors_off:before {
  content: "\eaf3";
}
.icon_iso:before {
  content: "\eaf4";
}
.icon_keyboard:before {
  content: "\eaf5";
}
.icon_keyboard_arrow_down:before {
  content: "\eaf6";
}
.icon_keyboard_arrow_left:before {
  content: "\eaf7";
}
.icon_keyboard_arrow_right:before {
  content: "\eaf8";
}
.icon_keyboard_arrow_up:before {
  content: "\eaf9";
}
.icon_keyboard_backspace:before {
  content: "\eafa";
}
.icon_keyboard_capslock:before {
  content: "\eafb";
}
.icon_keyboard_hide:before {
  content: "\eafc";
}
.icon_keyboard_return:before {
  content: "\eafd";
}
.icon_keyboard_tab:before {
  content: "\eafe";
}
.icon_keyboard_voice:before {
  content: "\eaff";
}
.icon_kitchen:before {
  content: "\eb00";
}
.icon_label:before {
  content: "\eb01";
}
.icon_label_outline:before {
  content: "\eb02";
}
.icon_landscape:before {
  content: "\eb03";
}
.icon_language:before {
  content: "\eb04";
}
.icon_laptop:before {
  content: "\eb05";
}
.icon_laptop_chromebook:before {
  content: "\eb06";
}
.icon_laptop_mac:before {
  content: "\eb07";
}
.icon_laptop_windows:before {
  content: "\eb08";
}
.icon_last_page:before {
  content: "\eb09";
}
.icon_launch:before {
  content: "\eb0a";
}
.icon_layers:before {
  content: "\eb0b";
}
.icon_layers_clear:before {
  content: "\eb0c";
}
.icon_leak_add:before {
  content: "\eb0d";
}
.icon_leak_remove:before {
  content: "\eb0e";
}
.icon_lens:before {
  content: "\eb0f";
}
.icon_library_add:before {
  content: "\eb10";
}
.icon_library_books:before {
  content: "\eb11";
}
.icon_library_music:before {
  content: "\eb12";
}
.icon_lightbulb_outline:before {
  content: "\eb13";
}
.icon_line_style:before {
  content: "\eb14";
}
.icon_line_weight:before {
  content: "\eb15";
}
.icon_linear_scale:before {
  content: "\eb16";
}
.icon_link2:before {
  content: "\eb17";
}
.icon_linked_camera:before {
  content: "\eb18";
}
.icon_list:before {
  content: "\eb19";
}
.icon_live_help:before {
  content: "\eb1a";
}
.icon_live_tv:before {
  content: "\eb1b";
}
.icon_local_activity:before {
  content: "\eb1c";
}
.icon_local_airport:before {
  content: "\eb1d";
}
.icon_local_atm:before {
  content: "\eb1e";
}
.icon_local_bar:before {
  content: "\eb1f";
}
.icon_local_cafe:before {
  content: "\eb20";
}
.icon_local_car_wash:before {
  content: "\eb21";
}
.icon_local_convenience_store:before {
  content: "\eb22";
}
.icon_local_dining:before {
  content: "\eb23";
}
.icon_local_drink:before {
  content: "\eb24";
}
.icon_local_florist:before {
  content: "\eb25";
}
.icon_local_gas_station:before {
  content: "\eb26";
}
.icon_local_grocery_store:before {
  content: "\eb27";
}
.icon_local_hospital:before {
  content: "\eb28";
}
.icon_local_hotel:before {
  content: "\eb29";
}
.icon_local_laundry_service:before {
  content: "\eb2a";
}
.icon_local_library:before {
  content: "\eb2b";
}
.icon_local_mall:before {
  content: "\eb2c";
}
.icon_local_movies:before {
  content: "\eb2d";
}
.icon_local_offer:before {
  content: "\eb2e";
}
.icon_local_parking:before {
  content: "\eb2f";
}
.icon_local_pharmacy:before {
  content: "\eb30";
}
.icon_local_phone:before {
  content: "\eb31";
}
.icon_local_pizza:before {
  content: "\eb32";
}
.icon_local_play:before {
  content: "\eb33";
}
.icon_local_post_office:before {
  content: "\eb34";
}
.icon_local_printshop:before {
  content: "\eb35";
}
.icon_local_see:before {
  content: "\eb36";
}
.icon_local_shipping:before {
  content: "\eb37";
}
.icon_local_taxi:before {
  content: "\eb38";
}
.icon_location_city:before {
  content: "\eb39";
}
.icon_location_disabled:before {
  content: "\eb3a";
}
.icon_location_off:before {
  content: "\eb3b";
}
.icon_location_on:before {
  content: "\eb3c";
}
.icon_location_searching:before {
  content: "\eb3d";
}
.icon_lock:before {
  content: "\eb3e";
}
.icon_lock_open:before {
  content: "\eb3f";
}
.icon_lock_outline:before {
  content: "\eb40";
}
.icon_looks:before {
  content: "\eb41";
}
.icon_looks_3:before {
  content: "\eb42";
}
.icon_looks_4:before {
  content: "\eb43";
}
.icon_looks_5:before {
  content: "\eb44";
}
.icon_looks_6:before {
  content: "\eb45";
}
.icon_looks_one:before {
  content: "\eb46";
}
.icon_looks_two:before {
  content: "\eb47";
}
.icon_loop:before {
  content: "\eb48";
}
.icon_loupe:before {
  content: "\eb49";
}
.icon_low_priority:before {
  content: "\eb4a";
}
.icon_loyalty:before {
  content: "\eb4b";
}
.icon_mic:before {
  content: "\eb4c";
}
.icon_mail:before {
  content: "\eb4d";
}
.icon_mail_outline:before {
  content: "\eb4e";
}
.icon_map:before {
  content: "\eb4f";
}
.icon_markunread:before {
  content: "\eb50";
}
.icon_markunread_mailbox:before {
  content: "\eb51";
}
.icon_memory:before {
  content: "\eb52";
}
.icon_menu:before {
  content: "\eb53";
}
.icon_merge_type:before {
  content: "\eb54";
}
.icon_message:before {
  content: "\eb55";
}
.icon_mms:before {
  content: "\eb56";
}
.icon_mnone:before {
  content: "\eb57";
}
.icon_mode_comment:before {
  content: "\eb58";
}
.icon_mode_edit:before {
  content: "\eb59";
}
.icon_moff:before {
  content: "\eb5a";
}
.icon_monetization_on:before {
  content: "\eb5b";
}
.icon_money_off:before {
  content: "\eb5c";
}
.icon_monochrome_photos:before {
  content: "\eb5d";
}
.icon_mood:before {
  content: "\eb5e";
}
.icon_mood_bad:before {
  content: "\eb5f";
}
.icon_more:before {
  content: "\eb60";
}
.icon_more_horiz:before {
  content: "\eb61";
}
.icon_more_vert:before {
  content: "\eb62";
}
.icon_motorcycle:before {
  content: "\eb63";
}
.icon_mouse:before {
  content: "\eb64";
}
.icon_move_to_inbox:before {
  content: "\eb65";
}
.icon_movie:before {
  content: "\eb66";
}
.icon_movie_creation:before {
  content: "\eb67";
}
.icon_movie_filter:before {
  content: "\eb68";
}
.icon_multiline_chart:before {
  content: "\eb69";
}
.icon_musnote:before {
  content: "\eb6a";
}
.icon_musvideo:before {
  content: "\eb6b";
}
.icon_my_location:before {
  content: "\eb6c";
}
.icon_nature:before {
  content: "\eb6d";
}
.icon_nature_people:before {
  content: "\eb6e";
}
.icon_navigate_before:before {
  content: "\eb6f";
}
.icon_navigate_next:before {
  content: "\eb70";
}
.icon_navigation:before {
  content: "\eb71";
}
.icon_near_me:before {
  content: "\eb72";
}
.icon_network_cell:before {
  content: "\eb73";
}
.icon_network_check:before {
  content: "\eb74";
}
.icon_network_locked:before {
  content: "\eb75";
}
.icon_network_wifi:before {
  content: "\eb76";
}
.icon_new_releases:before {
  content: "\eb77";
}
.icon_next_week:before {
  content: "\eb78";
}
.icon_nfc:before {
  content: "\eb79";
}
.icon_no_encryption:before {
  content: "\eb7a";
}
.icon_no_sim:before {
  content: "\eb7b";
}
.icon_not_interested:before {
  content: "\eb7c";
}
.icon_note:before {
  content: "\eb7d";
}
.icon_note_add:before {
  content: "\eb7e";
}
.icon_notifications:before {
  content: "\eb7f";
}
.icon_notifications_active:before {
  content: "\eb80";
}
.icon_notifications_none:before {
  content: "\eb81";
}
.icon_notifications_off:before {
  content: "\eb82";
}
.icon_notifications_paused:before {
  content: "\eb83";
}
.icon_offline_pin:before {
  content: "\eb84";
}
.icon_ondemand_video:before {
  content: "\eb85";
}
.icon_opacity:before {
  content: "\eb86";
}
.icon_open_in_browser:before {
  content: "\eb87";
}
.icon_open_in_new:before {
  content: "\eb88";
}
.icon_open_with:before {
  content: "\eb89";
}
.icon_pages:before {
  content: "\eb8a";
}
.icon_pageview:before {
  content: "\eb8b";
}
.icon_palette:before {
  content: "\eb8c";
}
.icon_pan_tool:before {
  content: "\eb8d";
}
.icon_panorama:before {
  content: "\eb8e";
}
.icon_panorama_fish_eye:before {
  content: "\eb8f";
}
.icon_panorama_horizontal:before {
  content: "\eb90";
}
.icon_panorama_vertical:before {
  content: "\eb91";
}
.icon_panorama_wide_angle:before {
  content: "\eb92";
}
.icon_party_mode:before {
  content: "\eb93";
}
.icon_pause:before {
  content: "\eb94";
}
.icon_pause_circle_filled:before {
  content: "\eb95";
}
.icon_pause_circle_outline:before {
  content: "\eb96";
}
.icon_payment:before {
  content: "\eb97";
}
.icon_people:before {
  content: "\eb98";
}
.icon_people_outline:before {
  content: "\eb99";
}
.icon_perm_camera_mic:before {
  content: "\eb9a";
}
.icon_perm_contact_calendar:before {
  content: "\eb9b";
}
.icon_perm_data_setting:before {
  content: "\eb9c";
}
.icon_perm_device_information:before {
  content: "\eb9d";
}
.icon_perm_identity:before {
  content: "\eb9e";
}
.icon_perm_media:before {
  content: "\eb9f";
}
.icon_perm_phone_msg:before {
  content: "\eba0";
}
.icon_perm_scan_wifi:before {
  content: "\eba1";
}
.icon_person:before {
  content: "\eba2";
}
.icon_person_add:before {
  content: "\eba3";
}
.icon_person_outline:before {
  content: "\eba4";
}
.icon_person_pin:before {
  content: "\eba5";
}
.icon_person_pin_circle:before {
  content: "\eba6";
}
.icon_personal_video:before {
  content: "\eba7";
}
.icon_pets:before {
  content: "\eba8";
}
.icon_phone:before {
  content: "\eba9";
}
.icon_phone_android:before {
  content: "\ebaa";
}
.icon_phone_bluetooth_speaker:before {
  content: "\ebab";
}
.icon_phone_forwarded:before {
  content: "\ebac";
}
.icon_phone_in_talk:before {
  content: "\ebad";
}
.icon_phone_iphone:before {
  content: "\ebae";
}
.icon_phone_locked:before {
  content: "\ebaf";
}
.icon_phone_missed:before {
  content: "\ebb0";
}
.icon_phone_paused:before {
  content: "\ebb1";
}
.icon_phonelink:before {
  content: "\ebb2";
}
.icon_phonelink_erase:before {
  content: "\ebb3";
}
.icon_phonelink_lock:before {
  content: "\ebb4";
}
.icon_phonelink_off:before {
  content: "\ebb5";
}
.icon_phonelink_ring:before {
  content: "\ebb6";
}
.icon_phonelink_setup:before {
  content: "\ebb7";
}
.icon_photo2:before {
  content: "\ebb8";
}
.icon_photo_album:before {
  content: "\ebb9";
}
.icon_photo_camera:before {
  content: "\ebba";
}
.icon_photo_filter:before {
  content: "\ebbb";
}
.icon_photo_library:before {
  content: "\ebbc";
}
.icon_photo_size_select_actual:before {
  content: "\ebbd";
}
.icon_photo_size_select_large:before {
  content: "\ebbe";
}
.icon_photo_size_select_small:before {
  content: "\ebbf";
}
.icon_picture_as_pdf:before {
  content: "\ebc0";
}
.icon_picture_in_picture:before {
  content: "\ebc1";
}
.icon_picture_in_picture_alt:before {
  content: "\ebc2";
}
.icon_pie_chart:before {
  content: "\ebc3";
}
.icon_pie_chart_outlined:before {
  content: "\ebc4";
}
.icon_pin_drop:before {
  content: "\ebc5";
}
.icon_place:before {
  content: "\ebc6";
}
.icon_play_arrow:before {
  content: "\ebc7";
}
.icon_play_circle_filled:before {
  content: "\ebc8";
}
.icon_play_circle_outline:before {
  content: "\ebc9";
}
.icon_play_for_work:before {
  content: "\ebca";
}
.icon_playlist_add:before {
  content: "\ebcb";
}
.icon_playlist_add_check:before {
  content: "\ebcc";
}
.icon_playlist_play:before {
  content: "\ebcd";
}
.icon_plus_one:before {
  content: "\ebce";
}
.icon_poll:before {
  content: "\ebcf";
}
.icon_polymer:before {
  content: "\ebd0";
}
.icon_pool:before {
  content: "\ebd1";
}
.icon_portable_wifi_off:before {
  content: "\ebd2";
}
.icon_portrait:before {
  content: "\ebd3";
}
.icon_power:before {
  content: "\ebd4";
}
.icon_power_input:before {
  content: "\ebd5";
}
.icon_power_settings_new:before {
  content: "\ebd6";
}
.icon_pregnant_woman:before {
  content: "\ebd7";
}
.icon_present_to_all:before {
  content: "\ebd8";
}
.icon_print:before {
  content: "\ebd9";
}
.icon_priority_high:before {
  content: "\ebda";
}
.icon_public:before {
  content: "\ebdb";
}
.icon_publish:before {
  content: "\ebdc";
}
.icon_query_builder:before {
  content: "\ebdd";
}
.icon_question_answer:before {
  content: "\ebde";
}
.icon_queue:before {
  content: "\ebdf";
}
.icon_queue_music:before {
  content: "\ebe0";
}
.icon_queue_play_next:before {
  content: "\ebe1";
}
.icon_radio:before {
  content: "\ebe2";
}
.icon_radio_button_checked:before {
  content: "\ebe3";
}
.icon_radio_button_unchecked:before {
  content: "\ebe4";
}
.icon_rate_review:before {
  content: "\ebe5";
}
.icon_receipt:before {
  content: "\ebe6";
}
.icon_recent_actors:before {
  content: "\ebe7";
}
.icon_record_voice_over:before {
  content: "\ebe8";
}
.icon_redeem:before {
  content: "\ebe9";
}
.icon_redo:before {
  content: "\ebea";
}
.icon_refresh:before {
  content: "\ebeb";
}
.icon_remove:before {
  content: "\ebec";
}
.icon_remove_circle:before {
  content: "\ebed";
}
.icon_remove_circle_outline:before {
  content: "\ebee";
}
.icon_remove_from_queue:before {
  content: "\ebef";
}
.icon_remove_red_eye:before {
  content: "\ebf0";
}
.icon_remove_shopping_cart:before {
  content: "\ebf1";
}
.icon_reorder:before {
  content: "\ebf2";
}
.icon_repeat:before {
  content: "\ebf3";
}
.icon_repeat_one:before {
  content: "\ebf4";
}
.icon_replay:before {
  content: "\ebf5";
}
.icon_replay_10:before {
  content: "\ebf6";
}
.icon_replay_30:before {
  content: "\ebf7";
}
.icon_replay_5:before {
  content: "\ebf8";
}
.icon_reply:before {
  content: "\ebf9";
}
.icon_reply_all:before {
  content: "\ebfa";
}
.icon_report:before {
  content: "\ebfb";
}
.icon_report_problem:before {
  content: "\ebfc";
}
.icon_restaurant:before {
  content: "\ebfd";
}
.icon_restaurant_menu:before {
  content: "\ebfe";
}
.icon_restore:before {
  content: "\ebff";
}
.icon_restore_page:before {
  content: "\ec00";
}
.icon_ring_volume:before {
  content: "\ec01";
}
.icon_room:before {
  content: "\ec02";
}
.icon_room_service:before {
  content: "\ec03";
}
.icon_rotate_90_degrees_ccw:before {
  content: "\ec04";
}
.icon_rotate_left:before {
  content: "\ec05";
}
.icon_rotate_right:before {
  content: "\ec06";
}
.icon_rounded_corner:before {
  content: "\ec07";
}
.icon_router:before {
  content: "\ec08";
}
.icon_rowing:before {
  content: "\ec09";
}
.icon_rss_feed:before {
  content: "\ec0a";
}
.icon_rv_hookup:before {
  content: "\ec0b";
}
.icon_satellite:before {
  content: "\ec0c";
}
.icon_save:before {
  content: "\ec0d";
}
.icon_scanner:before {
  content: "\ec0e";
}
.icon_schedule:before {
  content: "\ec0f";
}
.icon_school:before {
  content: "\ec10";
}
.icon_screen_lock_landscape:before {
  content: "\ec11";
}
.icon_screen_lock_portrait:before {
  content: "\ec12";
}
.icon_screen_lock_rotation:before {
  content: "\ec13";
}
.icon_screen_rotation:before {
  content: "\ec14";
}
.icon_screen_share:before {
  content: "\ec15";
}
.icon_sd_card:before {
  content: "\ec16";
}
.icon_sd_storage:before {
  content: "\ec17";
}
.icon_search:before {
  content: "\ec18";
}
.icon_security:before {
  content: "\ec19";
}
.icon_select_all:before {
  content: "\ec1a";
}
.icon_send:before {
  content: "\ec1b";
}
.icon_sentiment_dissatisfied:before {
  content: "\ec1c";
}
.icon_sentiment_neutral:before {
  content: "\ec1d";
}
.icon_sentiment_satisfied:before {
  content: "\ec1e";
}
.icon_sentiment_very_dissatisfied:before {
  content: "\ec1f";
}
.icon_sentiment_very_satisfied:before {
  content: "\ec20";
}
.icon_settings:before {
  content: "\ec21";
}
.icon_settings_applications:before {
  content: "\ec22";
}
.icon_settings_backup_restore:before {
  content: "\ec23";
}
.icon_settings_bluetooth:before {
  content: "\ec24";
}
.icon_settings_brightness:before {
  content: "\ec25";
}
.icon_settings_cell:before {
  content: "\ec26";
}
.icon_settings_ethernet:before {
  content: "\ec27";
}
.icon_settings_input_antenna:before {
  content: "\ec28";
}
.icon_settings_input_component:before {
  content: "\ec29";
}
.icon_settings_input_composite:before {
  content: "\ec2a";
}
.icon_settings_input_hdmi:before {
  content: "\ec2b";
}
.icon_settings_input_svideo:before {
  content: "\ec2c";
}
.icon_settings_overscan:before {
  content: "\ec2d";
}
.icon_settings_phone:before {
  content: "\ec2e";
}
.icon_settings_power:before {
  content: "\ec2f";
}
.icon_settings_remote:before {
  content: "\ec30";
}
.icon_settings_system_daydream:before {
  content: "\ec31";
}
.icon_settings_voice:before {
  content: "\ec32";
}
.icon_share:before {
  content: "\ec33";
}
.icon_shop:before {
  content: "\ec34";
}
.icon_shop_two:before {
  content: "\ec35";
}
.icon_shopping_basket:before {
  content: "\ec36";
}
.icon_shopping_cart:before {
  content: "\ec37";
}
.icon_short_text:before {
  content: "\ec38";
}
.icon_show_chart:before {
  content: "\ec39";
}
.icon_shuffle:before {
  content: "\ec3a";
}
.icon_signal_cellular_0_bar:before {
  content: "\ec3b";
}
.icon_signal_cellular_1_bar:before {
  content: "\ec3c";
}
.icon_signal_cellular_2_bar:before {
  content: "\ec3d";
}
.icon_signal_cellular_3_bar:before {
  content: "\ec3e";
}
.icon_signal_cellular_4_bar:before {
  content: "\ec3f";
}
.icon_signal_cellular_connected_no_internet_0_bar:before {
  content: "\ec40";
}
.icon_signal_cellular_connected_no_internet_1_bar:before {
  content: "\ec41";
}
.icon_signal_cellular_connected_no_internet_2_bar:before {
  content: "\ec42";
}
.icon_signal_cellular_connected_no_internet_3_bar:before {
  content: "\ec43";
}
.icon_signal_cellular_connected_no_internet_4_bar:before {
  content: "\ec44";
}
.icon_signal_cellular_no_sim:before {
  content: "\ec45";
}
.icon_signal_cellular_null:before {
  content: "\ec46";
}
.icon_signal_cellular_off:before {
  content: "\ec47";
}
.icon_signal_wifi_0_bar:before {
  content: "\ec48";
}
.icon_signal_wifi_1_bar:before {
  content: "\ec49";
}
.icon_signal_wifi_1_bar_lock:before {
  content: "\ec4a";
}
.icon_signal_wifi_2_bar:before {
  content: "\ec4b";
}
.icon_signal_wifi_2_bar_lock:before {
  content: "\ec4c";
}
.icon_signal_wifi_3_bar:before {
  content: "\ec4d";
}
.icon_signal_wifi_3_bar_lock:before {
  content: "\ec4e";
}
.icon_signal_wifi_4_bar:before {
  content: "\ec4f";
}
.icon_signal_wifi_4_bar_lock:before {
  content: "\ec50";
}
.icon_signal_wifi_off:before {
  content: "\ec51";
}
.icon_sim_card:before {
  content: "\ec52";
}
.icon_sim_card_alert:before {
  content: "\ec53";
}
.icon_skip_next:before {
  content: "\ec54";
}
.icon_skip_previous:before {
  content: "\ec55";
}
.icon_slideshow:before {
  content: "\ec56";
}
.icon_slow_motion_video:before {
  content: "\ec57";
}
.icon_smartphone:before {
  content: "\ec58";
}
.icon_smoke_free:before {
  content: "\ec59";
}
.icon_smoking_rooms:before {
  content: "\ec5a";
}
.icon_sms:before {
  content: "\ec5b";
}
.icon_sms_failed:before {
  content: "\ec5c";
}
.icon_snooze:before {
  content: "\ec5d";
}
.icon_sort:before {
  content: "\ec5e";
}
.icon_sort_by_alpha:before {
  content: "\ec5f";
}
.icon_spa:before {
  content: "\ec60";
}
.icon_space_bar:before {
  content: "\ec61";
}
.icon_speaker:before {
  content: "\ec62";
}
.icon_speaker_group:before {
  content: "\ec63";
}
.icon_speaker_notes:before {
  content: "\ec64";
}
.icon_speaker_notes_off:before {
  content: "\ec65";
}
.icon_speaker_phone:before {
  content: "\ec66";
}
.icon_spellcheck:before {
  content: "\ec67";
}
.icon_star:before {
  content: "\ec68";
}
.icon_star_border:before {
  content: "\ec69";
}
.icon_star_half:before {
  content: "\ec6a";
}
.icon_stars:before {
  content: "\ec6b";
}
.icon_stay_current_landscape:before {
  content: "\ec6c";
}
.icon_stay_current_portrait:before {
  content: "\ec6d";
}
.icon_stay_primary_landscape:before {
  content: "\ec6e";
}
.icon_stay_primary_portrait:before {
  content: "\ec6f";
}
.icon_stop:before {
  content: "\ec70";
}
.icon_stop_screen_share:before {
  content: "\ec71";
}
.icon_storage:before {
  content: "\ec72";
}
.icon_store:before {
  content: "\ec73";
}
.icon_store_mall_directory:before {
  content: "\ec74";
}
.icon_straighten:before {
  content: "\ec75";
}
.icon_streetview:before {
  content: "\ec76";
}
.icon_strikethrough_s:before {
  content: "\ec77";
}
.icon_style:before {
  content: "\ec78";
}
.icon_subdirectory_arrow_left:before {
  content: "\ec79";
}
.icon_subdirectory_arrow_right:before {
  content: "\ec7a";
}
.icon_subject:before {
  content: "\ec7b";
}
.icon_subscriptions:before {
  content: "\ec7c";
}
.icon_subtitles:before {
  content: "\ec7d";
}
.icon_subway:before {
  content: "\ec7e";
}
.icon_supervisor_account:before {
  content: "\ec7f";
}
.icon_surround_sound:before {
  content: "\ec80";
}
.icon_swap_calls:before {
  content: "\ec81";
}
.icon_swap_horiz:before {
  content: "\ec82";
}
.icon_swap_vert:before {
  content: "\ec83";
}
.icon_swap_vertical_circle:before {
  content: "\ec84";
}
.icon_switch_camera:before {
  content: "\ec85";
}
.icon_switch_video:before {
  content: "\ec86";
}
.icon_sync:before {
  content: "\ec87";
}
.icon_sync_disabled:before {
  content: "\ec88";
}
.icon_sync_problem:before {
  content: "\ec89";
}
.icon_system_update:before {
  content: "\ec8a";
}
.icon_system_update_alt:before {
  content: "\ec8b";
}
.icon_tab:before {
  content: "\ec8c";
}
.icon_tab_unselected:before {
  content: "\ec8d";
}
.icon_tablet:before {
  content: "\ec8e";
}
.icon_tablet_android:before {
  content: "\ec8f";
}
.icon_tablet_mac:before {
  content: "\ec90";
}
.icon_tag_faces:before {
  content: "\ec91";
}
.icon_tap_and_play:before {
  content: "\ec92";
}
.icon_terrain:before {
  content: "\ec93";
}
.icon_text_fields:before {
  content: "\ec94";
}
.icon_text_format:before {
  content: "\ec95";
}
.icon_textsms:before {
  content: "\ec96";
}
.icon_texture:before {
  content: "\ec97";
}
.icon_theaters:before {
  content: "\ec98";
}
.icon_thumb_down:before {
  content: "\ec99";
}
.icon_thumb_up:before {
  content: "\ec9a";
}
.icon_thumbs_up_down:before {
  content: "\ec9b";
}
.icon_time_to_leave:before {
  content: "\ec9c";
}
.icon_timelapse:before {
  content: "\ec9d";
}
.icon_timeline:before {
  content: "\ec9e";
}
.icon_timer:before {
  content: "\ec9f";
}
.icon_timer_10:before {
  content: "\eca0";
}
.icon_timer_3:before {
  content: "\eca1";
}
.icon_timer_off:before {
  content: "\eca2";
}
.icon_title:before {
  content: "\eca3";
}
.icon_toc:before {
  content: "\eca4";
}
.icon_today:before {
  content: "\eca5";
}
.icon_toll:before {
  content: "\eca6";
}
.icon_tonality:before {
  content: "\eca7";
}
.icon_touch_app:before {
  content: "\eca8";
}
.icon_toys:before {
  content: "\eca9";
}
.icon_track_changes:before {
  content: "\ecaa";
}
.icon_traffic:before {
  content: "\ecab";
}
.icon_train:before {
  content: "\ecac";
}
.icon_tram:before {
  content: "\ecad";
}
.icon_transfer_within_a_station:before {
  content: "\ecae";
}
.icon_transform:before {
  content: "\ecaf";
}
.icon_translate:before {
  content: "\ecb0";
}
.icon_trending_down:before {
  content: "\ecb1";
}
.icon_trending_flat:before {
  content: "\ecb2";
}
.icon_trending_up:before {
  content: "\ecb3";
}
.icon_tune:before {
  content: "\ecb4";
}
.icon_turned_in:before {
  content: "\ecb5";
}
.icon_turned_in_not:before {
  content: "\ecb6";
}
.icon_tv:before {
  content: "\ecb7";
}
.icon_unarchive:before {
  content: "\ecb8";
}
.icon_undo:before {
  content: "\ecb9";
}
.icon_unfold_less:before {
  content: "\ecba";
}
.icon_unfold_more:before {
  content: "\ecbb";
}
.icon_update:before {
  content: "\ecbc";
}
.icon_usb:before {
  content: "\ecbd";
}
.icon_verified_user:before {
  content: "\ecbe";
}
.icon_vertical_align_bottom:before {
  content: "\ecbf";
}
.icon_vertical_align_center:before {
  content: "\ecc0";
}
.icon_vertical_align_top:before {
  content: "\ecc1";
}
.icon_vibration:before {
  content: "\ecc2";
}
.icon_video_call:before {
  content: "\ecc3";
}
.icon_video_label:before {
  content: "\ecc4";
}
.icon_video_library:before {
  content: "\ecc5";
}
.icon_videocam:before {
  content: "\ecc6";
}
.icon_videocam_off:before {
  content: "\ecc7";
}
.icon_videogame_asset:before {
  content: "\ecc8";
}
.icon_view_agenda:before {
  content: "\ecc9";
}
.icon_view_array:before {
  content: "\ecca";
}
.icon_view_carousel:before {
  content: "\eccb";
}
.icon_view_column:before {
  content: "\eccc";
}
.icon_view_comfy:before {
  content: "\eccd";
}
.icon_view_compact:before {
  content: "\ecce";
}
.icon_view_day:before {
  content: "\eccf";
}
.icon_view_headline:before {
  content: "\ecd0";
}
.icon_view_list:before {
  content: "\ecd1";
}
.icon_view_module:before {
  content: "\ecd2";
}
.icon_view_quilt:before {
  content: "\ecd3";
}
.icon_view_stream:before {
  content: "\ecd4";
}
.icon_view_week:before {
  content: "\ecd5";
}
.icon_vignette:before {
  content: "\ecd6";
}
.icon_visibility:before {
  content: "\ecd7";
}
.icon_visibility_off:before {
  content: "\ecd8";
}
.icon_voice_chat:before {
  content: "\ecd9";
}
.icon_voicemail:before {
  content: "\ecda";
}
.icon_volume_down:before {
  content: "\ecdb";
}
.icon_volume_mute:before {
  content: "\ecdc";
}
.icon_volume_off:before {
  content: "\ecdd";
}
.icon_volume_up:before {
  content: "\ecde";
}
.icon_vpn_key:before {
  content: "\ecdf";
}
.icon_vpn_lock:before {
  content: "\ece0";
}
.icon_wallpaper:before {
  content: "\ece1";
}
.icon_watch:before {
  content: "\ece2";
}
.icon_watch_later:before {
  content: "\ece3";
}
.icon_wb_auto:before {
  content: "\ece4";
}
.icon_wb_cloudy:before {
  content: "\ece5";
}
.icon_wb_incandescent:before {
  content: "\ece6";
}
.icon_wb_iridescent:before {
  content: "\ece7";
}
.icon_wb_sunny:before {
  content: "\ece8";
}
.icon_wc:before {
  content: "\ece9";
}
.icon_web:before {
  content: "\ecea";
}
.icon_web_asset:before {
  content: "\eceb";
}
.icon_weekend:before {
  content: "\ecec";
}
.icon_whatshot:before {
  content: "\eced";
}
.icon_widgets:before {
  content: "\ecee";
}
.icon_wifi:before {
  content: "\ecef";
}
.icon_wifi_lock:before {
  content: "\ecf0";
}
.icon_wifi_tethering:before {
  content: "\ecf1";
}
.icon_work:before {
  content: "\ecf2";
}
.icon_wrap_text:before {
  content: "\ecf3";
}
.icon_youtube_searched_for:before {
  content: "\ecf4";
}
.icon_zoom_in:before {
  content: "\ecf5";
}
.icon_zoom_out:before {
  content: "\ecf6";
}
.icon_zoom_out_map:before {
  content: "\ecf7";
}
.icon_v_format_font:before {
  content: "\ecf8";
}
.icon_v_hide:before {
  content: "\ecf9";
}
.icon_v_important:before {
  content: "\ecfa";
}
.icon_v_lock_internal:before {
  content: "\ecfb";
}
.icon_v_comments_drafts:before {
  content: "\ecfc";
}
.icon_v_pin_off:before {
  content: "\ecfd";
}
.icon_v_pin_stream_off:before {
  content: "\ecfe";
}
.icon_v_salesforce_logo:before {
  content: "\ecff";
}
.icon_v_lock_external:before {
  content: "\ed00";
}
.icon_v_pin_stream_pin_only:before {
  content: "\ed01";
}
.icon_v_pin_stream_off_no_opacity:before {
  content: "\ed02";
}
.icon_v_process:before {
  content: "\ed03";
}
.icon_v_invite_received:before {
  content: "\ed04";
}
.icon_v_invite_sent:before {
  content: "\ed05";
}
.icon_v_task_action:before {
  content: "\ed06";
}
.icon_v_task_approval:before {
  content: "\ed07";
}
.icon_v_event:before {
  content: "\ed08";
}
.icon_v_explicit_read:before {
  content: "\ed09";
}
.icon_v_continue_comment:before {
  content: "\ed0a";
}
.icon_v_reply:before {
  content: "\ed0b";
}
.icon_v_forward:before {
  content: "\ed0c";
}
.icon_v_resize:before {
  content: "\ed0d";
}
.icon_v_focus:before {
  content: "\ed0e";
}
.icon_v_standard_zone:before {
  content: "\ed0f";
}
.icon_v_focus_zone:before {
  content: "\ed10";
}
.icon_v_page_zone:before {
  content: "\ed11";
}
.icon_v_manage_zones:before {
  content: "\ed12";
}
.icon_v_notifications:before {
  content: "\ed13";
}
.icon_v_lightbox_max:before {
  content: "\ed14";
}
.icon_v_lightbox_min:before {
  content: "\ed15";
}
.icon_v_more_results:before {
  content: "\ed16";
}
.icon_v_insight_off:before {
  content: "\ed17";
}
.icon_v_insight_on:before {
  content: "\ed18";
}
.icon_v_previous:before {
  content: "\ed19";
}
.icon_v_stream:before {
  content: "\ed1a";
}
.icon_v_project:before {
  content: "\ed1b";
}
.icon_v_robot:before {
  content: "\ed1c";
}
.icon_v_magic_on:before {
  content: "\ed1d";
}
.icon_v_magic_off:before {
  content: "\ed1e";
}
.icon_v_qr:before {
  content: "\ed1f";
}
.icon_v_capsule:before {
  content: "\ed20";
}
.icon_v_core:before {
  content: "\ed21";
}
.icon_v_guidelines:before {
  content: "\ed22";
}
.icon_v_engagement:before {
  content: "\ed23";
}
.icon_v_human_resources:before {
  content: "\ed24";
}
.icon_v_employee_training:before {
  content: "\ed25";
}
.icon_v_employee_onboarding:before {
  content: "\ed26";
}
.icon_v_product_request:before {
  content: "\ed27";
}
.icon_v_feedback:before {
  content: "\ed28";
}
.icon_v_task_announcements:before {
  content: "\ed29";
}
.icon_v_action_item_off:before {
  content: "\ed2a";
}
.icon_v_action_item_on:before {
  content: "\ed2b";
}
.icon_v_action_item_back:before {
  content: "\ed2c";
}
.icon_v_action_item_front:before {
  content: "\ed2d";
}
.icon_v_action_item:before {
  content: "\ed2e";
}
.icon_v_issue_flag:before {
  content: "\ed2f";
}
.icon_v_tasks:before {
  content: "\ed30";
}
.icon_v_task_review:before {
  content: "\ed31";
}
.icon_v_task_acknowledge:before {
  content: "\ed32";
}
.icon_v_task_project:before {
  content: "\ed33";
}
.icon_v_task:before {
  content: "\ed34";
}
.icon_battery0bar:before {
  content: "\ed35";
}
.icon_battery1bar:before {
  content: "\ed36";
}
.icon_battery2bar:before {
  content: "\ed37";
}
.icon_battery3bar:before {
  content: "\ed38";
}
.icon_battery4bar:before {
  content: "\ed39";
}
.icon_battery5bar:before {
  content: "\ed3a";
}
.icon_battery6bar:before {
  content: "\ed3b";
}
.icon_battery0bar_charging:before {
  content: "\ed3c";
}
.icon_battery1bar_charging:before {
  content: "\ed3d";
}
.icon_battery2bar_charging:before {
  content: "\ed3e";
}
.icon_battery3bar_charging:before {
  content: "\ed3f";
}
.icon_battery4bar_charging:before {
  content: "\ed40";
}
.icon_battery5bar_charging:before {
  content: "\ed41";
}
.icon_battery6bar_charging:before {
  content: "\ed42";
}
.icon_battery1bar_alert:before {
  content: "\ed44";
}
.icon_battery2bar_alert:before {
  content: "\ed45";
}
.icon_battery3bar_alert:before {
  content: "\ed46";
}
.icon_battery4bar_alert:before {
  content: "\ed47";
}
.icon_battery5bar_alert:before {
  content: "\ed48";
}
.icon_battery6bar_alert:before {
  content: "\ed49";
}
.icon_v_meeting_recurrent:before {
  content: "\ed4a";
}
.icon_v_firm_auditory:before {
  content: "\ed4b";
}
.icon_v_firm_clear:before {
  content: "\ed4c";
}
.icon_v_firm_clarity:before {
  content: "\ed4d";
}
.icon_v_firm_sleep:before {
  content: "\ed4e";
}
.icon_v_audiogram:before {
  content: "\ed4f";
}
.icon_v_survey:before {
  content: "\ed50";
}
.icon_v_history:before {
  content: "\ed51";
}
.icon_v_trend:before {
  content: "\ed52";
}
.icon_v_more:before {
  content: "\ed53";
}
.icon_v_arrow_right_circle:before {
  content: "\ed54";
}
